import React from 'react'
import ratingStar from "../../../assets/images/rating-star.svg"
import { ReactComponent as Star } from "../../../assets/images/star-icon.svg"
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg"
import placeholder from "../../../assets/images/placeholder.png"
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { adminApiHelper } from '../../../redux/adminApiHelper';

function Reviews({ reviewsData }) {

	const [rating, setRating] = useState(0);
	const userId = JSON.parse(localStorage.getItem('userId'));
	const [formSubmitted, setFormSubmitted] = useState(false);
	const schema = yup.object().shape({
		name: yup.string().required('مطلوب اسم').min(3, 'قصير جدا').max(30, 'طويل جدا'),
		comment: yup.string().required('الرسالة مطلوبة').min(4, 'قصير جدا').max(700, 'طويل جدا'),
	});

	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema)
	});

	const handleStarClick = (value) => {
		setRating(value);
	};

	const onSubmit = async (formData) => {
		const courseId = reviewsData?.courseId
		formData.rating = rating;
		formData.userId = userId;
		formData.courseId = courseId;
		try {
			setFormSubmitted(true);
			if (rating === 0) {
				// toast.error('يرجى إعطاء نجمة واحدة على الأقل');
			} else {
				let res = await adminApiHelper('post', `/courseReview/?courseId=${courseId}&userId=${userId}`, formData);
				if (res?.data) {
					const { data } = res;
					toast.success(data.message);
					setRating(0);
					reset();
			setFormSubmitted(false);

				}
			}
		} catch (error) {
			toast.error(error.response.message);
			console.error(error);
		}
	};

	return (
		<div>
			<section className='reviews--section 2xl:py-24 xl:pb-10 xl:pt-6 lg:py-8 py-6'>
				<div className='theme__container'>
					<div className='cart--content mt-2 lg:mr-6'>
					</div>
					<div className="sm:grid cmd:grid-cols-2 gap-10 mt-12">
						<div className="right-reviews-section ">
							<h2 className='2xl:text-7xl xl:text-5xl lg:text-4xl text-3xl font-light dark_color'> آراء المشتركين السابقين</h2>
							<div className='right-reviews-content md:pt-16 pt-10'>
								<ul className='right-reviews-list'>
									{reviewsData.reviews?.length === 0 ? (
										<div className='common-card-style text-center'>
											<h6>No Reviews Found</h6>
										</div>
									) : (
										reviewsData.reviews?.filter((item) => item.status === 'Accepted').length === 0 ? (
											<div className='common-card-style text-center'>
												<h6>No Reviews Found</h6>
											</div>
										) : (
											reviewsData.reviews?.filter((item) => item.status === 'Accepted').map((review, index) => {
												const rating = review?.rating || 0;
												const starIcons = Array.from({ length: 5 }, (_, i) => (
													<div className='start_icon'>
														{i < rating ? <Star /> : < StarIcon className="blank_svg" />}
													</div>
												));
												return (<li className='common-card-style' key={index}>
													<div className='md:flex flex-column justify-between'>
														<div className='flex'>
															<div className='naming'>
																<h6>{review.name}</h6>
															</div>
															<div className='review-dates mr-5 lato'>
																{moment(review.createdAt).format('MM/DD/YYYY')}
															</div>
														</div>
														<div className='flex'>
															<ul className='start-list'>
																{starIcons}
															</ul>
														</div>
													</div>
													<div className='reveiw--msg flex items-center'>
														<div className='profile-img'>
															<img src={placeholder} alt='cart Image' />
														</div>
														<div className='message'>
															<p className='dark_color 2xl:text-lg xl:text-base text-sm font-light'>
																{review.comment}
															</p>
														</div>
													</div>
												</li>)
											})
										)
									)}
								</ul>
								<div>
								</div>
							</div>
						</div>
						<div className='left-reviews-section'>
							<h2 className='2xl:text-7xl xl:text-5xl lg:text-4xl text-3xl font-light dark_color'>رأيك يهمنا</h2>
							<div className='contact-form common-card-style md:mt-16 mt-10'>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="mb-3">
										<div className="col-12">
											<input type="text" className="form-control" id="name" placeholder="الاسم" name="name" {...register("name")} autoComplete="off" />
											{errors.name && <span className="color-red">{errors.name.message}</span>}
										</div>
									</div>
									<textarea className="form-control" rows="5" placeholder="أخبرنا عن تجربتك الفريدة" name="comment" {...register("comment")}></textarea>
									{errors.comment && <span className="color-red">{errors.comment.message}</span>}
									<div className='flex xl:py-8 lg:py-6 py-4'>
										<div className='flex'>
											<h6 className='dark_color 2xl:text-lg xl:text-base text-sm  font-bold'>تقييمك</h6>
										</div>
										<div className='flex mr-5'>
											<ul className='start-list'>
												{[1, 2, 3, 4, 5].map((value, index) => (
													<li key={index} onClick={() => handleStarClick(value)}>
														<img src={ratingStar} alt="cart Image" style={{ opacity: rating >= value ? 1 : 0.5 }} />
													</li>
												))}
											</ul>
											{formSubmitted && rating === 0 && (
												<div className="flex mr-5">
													<p className="color-red">يرجى إعطاء نجمة واحدة على الأقل</p>
												</div>
											)}
										</div>
									</div>
									<button className="theme-btn  flex justify-center items-center 2xl:mt-12 lg: md:mt-8 mt-5">ارسل لنا</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Reviews
