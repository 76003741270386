import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { courseListing } from '../../../redux/course/courseActions';

function CourseSlider({ sliderData }) {

    const dispatch = useDispatch();
    const coursesData = useSelector(state => state.course.courses)

    useEffect(() => {
        dispatch(courseListing(sliderData.category))
    }, [sliderData.category])

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        arrows: true,
        speed: 500,
        centerPadding: "0px",
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    centerPadding: "0px",
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                    infinite: true,
                }
            }
        ]
    };

    return (
        <div className='product__slider-wrap'>
            <div className='theme__container'>
                {coursesData?.courses?.length > 1 ?
                    <div className='shahdat-wrapper 2xl:mb-24 md:mb-20'>
                        <div className='product_shahat-text text-center'>
                            <h2 className='2xl:text-7xl xl:text-5xl lg:text-4xl text-3xl font-light'>دورات نبيلة الأخرى</h2>
                            <h5 className='light-yellow f-size-26 font-light 2xl:pb-12 md:pb-6 pb-4'>تحقق من الدورات الأخرى المتاحة لك</h5>
                        </div>
                        {coursesData?.courses?.length > 3 ?
                            <div>
                                <Slider {...settings}>
                                    {coursesData?.courses?.filter((item) => item._id !== sliderData?.courseId).map((course, index) => {
                                        const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                        const divStyle = {
                                            backgroundImage: `url(${imageUrl})`,
                                            backgroundRepeat: 'no-repeat'
                                        };
                                        return (
                                            <div className="product-wraper" key={index}>
                                                <div className='coleague-wrap coleagesecond p-4' style={divStyle}>
                                                    <div className='flex justify-between produtc-name-price relative z-1' >
                                                        <h5 className='text-white 2xl:text-lg xl:text-base text-sm'>{course.title}</h5>
                                                        <div className='left-ribon ribbon'>
                                                            {course.type === 'Free' ?
                                                                <h3 className="text-white text-lg bold">Free</h3>
                                                                : course.type === 'Subscribe' ? <h3 className="text-white text-lg bold">Subscription</h3>
                                                                    : <h3 className="text-white text-lg bold">{course.currency + '' + course.price}</h3>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='colleage-text text-end f-size-17 mt-4'>
                                                    <h6 className='light-yellow text-start'>{course.files?.length} فيديو مجموعة</h6>
                                                    <p className=' font-light mt-2 max-w-md ml-auto'>{course.shortDescription}</p>
                                                </div>
                                                <Link to={`/course/${course._id}`}>
                                                    <div className="product-overlay">
                                                        <div className=" text-center 2xl:mt-18 lg:mt-10 mt-16 w-full">
                                                            <button className='theme-btn '>تفاصيل</button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                            :
                            <>
                                {coursesData?.courses?.filter((item) => item._id !== sliderData?.courseId).map((course, index) => {
                                    const imageUrl = process.env.REACT_APP_FILE_URL + course?.thumbnailpic;
                                    const divStyle = {
                                        backgroundImage: `url(${imageUrl})`,
                                        backgroundRepeat: 'no-repeat'
                                    };
                                    return (
                                        <div className="ml-4 product-wraper" key={index}>
                                            <div className='coleague-wrap coleagesecond p-4' style={divStyle}>
                                                <div className='flex justify-between produtc-name-price relative z-1' >
                                                    <h5 className='text-white 2xl:text-lg xl:text-base text-sm'>{course.title}</h5>
                                                    <div className='left-ribon ribbon'>
                                                        {course.type === 'Free' ?
                                                            <h3 className="text-white text-lg bold">Free</h3>
                                                            : course.type === 'Subscribe' ? <h3 className="text-white text-lg bold">Subscription</h3>
                                                                : <h3 className="text-white text-lg bold">{course.currency + '' + course.price}</h3>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='colleage-text text-end f-size-17 mt-4'>
                                                <h6 className='light-yellow text-start'>{course.files?.length} فيديو مجموعة</h6>
                                                <p className=' font-light mt-2 max-w-md ml-auto text-justify'>{course.shortDescription}</p>
                                            </div>
                                            <Link to={`/course/${course._id}`}>
                                                <div className="product-overlay">
                                                    <div className=" text-center 2xl:mt-18 lg:mt-10 mt-16 w-full">
                                                        <button className='theme-btn '>تفاصيل</button>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    )
                                })}
                            </>
                        }
                    </div>
                    : <></>
                }
            </div>
        </div>
    )
}

export default CourseSlider
