import React, { useEffect } from 'react'
import "./AboutUs.scss"
import '../../../../src/assets/css/cmsbanner.css';

function AboutBanner() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <div className="cms-pages-baner about-baner">
                <div className="theme__container">
                    <div className='cms-content-heading'>
                        <h2 className='2xl:text-7xl lg:text-7xl md:text-5xl text-3xl  dark_color bold  xl:mb-5 mb-3'>
                        عالم م. نبيلة
                        </h2>
                        <p className='2xl:text-3xl xl:text-2xl md:text-lg text-base font-light dark_color'>
                        هل ترغب بالتواصل مع م.نبيلة؟ الآن يمكنك التواصل مباشرة مع م.نبيلة
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutBanner
