import React, { useEffect, useState } from 'react'
import light from "../../../assets/images/light.svg"
import arrowball from "../../../assets/images/arrow-ball.svg"
import calball from "../../../assets/images/calball.svg"
import shakeHand from "../../../assets/images/shakeHand.svg"
import { apiHelper } from '../../../redux/apiHelper'
import Loader from '../../../config/Loader'

function Benefit() {
  const [statistics, setStatistics] = useState();
  const [isLoading, setIsLoading] = useState(false);

    const fetchStatistics = async () => {
		setIsLoading(true);
        try {
          const res = await apiHelper("get", `/statistics`, "");
          if (res?.data) {
            const { data } = res;
            setStatistics(data);
			setIsLoading(false);
          }
        } catch (error) {
			setIsLoading(false);
            console.error(error)
        }
      };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchStatistics();
    }, []);

    return (
        <div className='benefit__wrapper relative 2xl:pt-20 lg:pt-16 md:pt-10 pt-6 pb-10'>
            <div className='theme__container relative z-10'>
            {isLoading ? (
					<Loader />
				) : (<>
                <div className='benefits_number flex items-center'>
                    <div className='number_content flex flex-col items-center   '>
                        <span className='about_num font-bold '>{statistics?.events}</span>
                        <span className='2xl:text-3xl lg:text-2xl text-xl font-light dark_color'>اللقاءات المفتوحة</span>
                    </div>
                    <div className='number_content flex flex-col items-center   '>
                        <span className='about_num font-bold '>{statistics?.users}</span>
                        <span className='2xl:text-3xl lg:text-2xl text-xl font-light dark_color'>المستخدمون</span>
                    </div>
                    <div className='number_content flex flex-col items-center   '>
                        <span className='about_num font-bold '>{statistics?.subscriptions}</span>
                        <span className='2xl:text-3xl lg:text-2xl text-xl font-light dark_color'>باقات الاشتراك</span>
                    </div>
                    <div className='number_content flex flex-col items-center   '>
                        <span className='about_num font-bold '>{statistics?.courses}</span>
                        <span className='2xl:text-3xl lg:text-2xl text-xl font-light dark_color'>الدورات</span>
                    </div>
                </div>
                </>)}
                <div className='benefit_heading text-center 2xl:pt-16 lg:pt-12 md:pt-8 pt-6'>
                    <h3>ما الذي يتميز به عالم نبيلة ؟</h3>
                    <p className='f-size-17 font-light dark_color pt-4'> عالم نبيلة يتميز بالمرونة وروح البهجة والحُب فأنت ستشعر بذلك من أول لحظة !</p>
                </div>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 about__card flex items-center mt-16'>
                        <div className='benefits_card text-center ml-5 '>
                            <span><img src={light} alt="light" className='m-auto' /></span>
                            <p className='f-size-17 dark-shadow mt-8 f-md-size'>عالم يُسهّل عليك استكشاف وجهات نظر جديدة، و التخلص من العادات والسلوكيات القديمة، واحتضان قوة التغيير في حياتك .</p>
                        </div>
                        <div className='benefits_card text-center ml-5 '>
                            <span><img src={arrowball} alt="light" className='m-auto' /></span>
                            <p className='f-size-17 dark-shadow mt-8 f-md-size'>عالم يجعلك تؤمن بقوة التشافي الذي وضعهُ الله بداخلك .</p>
                        </div>
                        <div className='benefits_card text-center ml-5 '>
                            <span><img src={calball} alt="light" className='m-auto' /></span>
                            <p className='f-size-17 dark-shadow mt-8 f-md-size'>عالم يساهم لك بكل يُسر في تغيير نظرتك لنفسك وإيجاد الطُرق النورانية التي تُقربّك من خالقك .</p>
                        </div>
                        <div className='benefits_card text-center ml-5 '>
                            <span><img src={shakeHand} alt="light" className='m-auto' /></span>
                            <p className='f-size-17 dark-shadow mt-8 f-md-size'>عالم يساعدك في اكتشاف قوة قلبك والعلم الذي وضعهُ الله فيك وتفعيل سمعك وبصرك .</p>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Benefit
