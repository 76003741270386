import React, { useState } from 'react'
import Eye from "../../../../assets/images/eye.svg"
import UserIcon from "../../../../assets/images/UserIcon.svg"
import { Link, useNavigate } from 'react-router-dom'
import site_logo from "../../../../assets/images/site_logo.png";
import "../Auth.scss"
import { useDispatch } from 'react-redux'
import { loginUser } from '../../../../redux/auth/authActions'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const schema = yup.object().shape({
        phone: yup.string().required("رقم الهاتف مطلوب").nullable(true),
        password: yup.string().required('كلمة المرور مطلوبة').nullable(true),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const handleFormSubmit = (data) => {
        dispatch(loginUser(data, navigate));
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <div className='login_In relative'>
                <div className='site-logo desktop-block mobile-hide'>
                    <Link to="/" className="site__logo pl-14">
                        <img src={site_logo} alt="site_logo" className='m-auto z-10 relative pt-6' />
                    </Link>
                </div>
                <div className='login-in_wraper 2xl:py-20 pt-2 pb-12 z-10 relative'>
                    <div className='theme__container flex'>
                        <div className='heading-content bg-white'>
                            <div className='auth-heading flex justify-between items-center'>
                                <h1 className='2xltext-4xl md:text-2xl font-bold'>تسجيل الدخول</h1>
                                <div className='user__icon'>
                                    <img src={UserIcon} alt="user" />
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <div className='form-box flex flex-col'>
                                    <label className='dark-color f-size-17 font-bold pb-4'>رقم الهاتف أو البريد الإلكتروني<sup className='staric'>*</sup></label>
                                    <input type="text" placeholder='أكتب هنا' {...register("phone")} />
                                    <span className='color-red'>{errors.phone?.message}</span>
                                </div>
                                <div className='form-box flex flex-col mt-5'>
                                    <label className='dark-color f-size-17 font-bold pb-4'>كلمة المرور<sup className='staric'>*</sup></label>
                                    <div className='relative'>
                                        <input type={showPassword ? "text" : "password"} placeholder='ادخل رقمك السري' {...register("password")} />
                                        <button type="button" className='eye-button' onClick={handleTogglePassword}>
                                            {showPassword ? <div className='slash-icon' > <img src={Eye} alt="eye" /></div> : <img src={Eye} alt="eye" />}
                                        </button>
                                        <span className='color-red'>{errors.password?.message}</span>
                                    </div>
                                </div>
                                {/* <div className='2xl:mt-12 lg: md:mt-8 mt-5'>
                                    <label className="custom-control overflow-checkbox">
                                        <input type="checkbox" className="overflow-control-input" />
                                        <span className="overflow-control-indicator "></span>
                                        <span className="overflow-control-description mr-5 2xl:text-lg xl:text-base text-sm dark_color font-light f-size-17 pr-2">تذكرنى</span>
                                    </label>
                                </div> */}
                                <div className='flex items-center 2xl:mt-12 mt-6 justify-between mbl_flex_col'>
                                    <div className="w-full  ">
                                        <button className="theme-btn">تسجيل الدخول</button>
                                    </div>
                                    <Link to="/forget" className='forget__butn'> <span className='color-red f-size-17'>هل فقدت كلمة المرور الخاصة بك؟</span></Link>
                                </div>
                                <div className='ribbon__wraper flex items-center 2xl:mt-28 lg:mt-14 md:mt-6 mt-8 '>
                                    <div className="ribbon 2xl:my-2 xl:my-5 my-1">
                                        <h3 className="text-white 2xl:text-lg xl:text-base lg:text-sm text-xs bold">هل انت عضو جديد في موقعنا ؟</h3>
                                    </div>
                                    <Link to="/signup"> <span className='color-red f-size-17 mr-3 '>أفتح حساب الأن</span></Link>                                  </div>
                            </form>
                        </div>
                        <div className='auth-left_banner'>
                            {/* <div className='login-text-left'>
                                <h2 className=''>
                                كُن في تناغم مع نفسك ومع الحياة !
                                </h2>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
