import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { adminApiHelper } from '../../../redux/adminApiHelper';
import './Events.scss'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Loader from '../../../config/Loader';

function Events() {

    const navigate = useNavigate();
    const currentDate = new Date().toISOString().split('T')[0];
    const [events, setEvents] = useState();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showShortDescription, setShowShortDescription] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const months = [
        { en: 'January', ar: 'يناير' },
        { en: 'February', ar: 'فبراير' },
        { en: 'March', ar: 'مارس' },
        { en: 'April', ar: 'أبريل' },
        { en: 'May', ar: 'مايو' },
        { en: 'June', ar: 'يونيو' },
        { en: 'July', ar: 'يوليو' },
        { en: 'August', ar: 'أغسطس' },
        { en: 'September', ar: 'سبتمبر' },
        { en: 'October', ar: 'أكتوبر' },
        { en: 'November', ar: 'نوفمبر' },
        { en: 'December', ar: 'ديسمبر' }
    ];
    var days = ["اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت", "اﻷحد"];
    var format = { PM: "مساءً", AM: "أكون" }

    async function fetchEvent() {
        setIsLoading(true)
        await adminApiHelper("get", `/events//?status=${true}&pagination=${false}&date=${currentDate}`).then((result) => {
            setEvents(result?.data?.event);
            setIsLoading(false)
            const storedUser = localStorage.getItem('user');
            const parsedData = JSON.parse(storedUser);
            setUser(parsedData);
        }).catch((err) => {
            console.log(err, "error")
        });
    }

    useEffect(() => {
        fetchEvent();
        window.scroll(0, 0)
    }, [])

    const handleJoinUs = (eventId) => {
        if (user) {
            adminApiHelper('post', `/events/join/${eventId}?userId=${user?.id}`)
                .then((response) => {
                    if (response?.data?.success) {
                        toast.success("Event joined successfully")
                        fetchEvent();
                    } else {
                        toast.error(response?.data?.message)
                    }
                })
                .catch((error) => {
                    console.error('Join us failed:', error);
                });
        } else {
            navigate("/login")
        }
    };

    const handlePay = (id) => {
        if (user) {
            navigate(`/checkout?id=${id}&type=event`)
        } else {
            navigate("/login")
        }
    };

    const toggleShortDescription = () => {
        setShowShortDescription(!showShortDescription);
    };

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    const isUserInvited = (eventId) => {
        if (events && user) {
            const event = events?.find((event) => event?._id === eventId);
            if (event && event?.invitees.includes(user.id)) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className='Events__wrapper'>
            <div className="cms-pages-baner event-baner">
                <div className="theme__container">
                    <div className='cms-content-heading'>
                        <h2 className='2xl:text-7xl lg:text-7xl md:text-5xl text-3xl  dark_color bold  xl:mb-5 mb-3'>
                        اللقاءات المفتوحة
                        </h2>
                        <p className='2xl:text-3xl xl:text-2xl md:text-lg text-base font-light dark_color'>
                        انضم إلينا في اللقاءات المفتوحة القادمة
                        </p>
                    </div>
                </div>
            </div>
            <section className='events_content 2xl:py-20 lg:py-16 md:py-10 py-8' >
                <div className='theme__container'>
                    <div className='events-heading text-center'>
                        <h2 className='f-size-70 font-light dark_color dark_shadow '>اللقاءات القادمة</h2>
                        <p className='f-size-17 font-light yellow-clr text-center'>احصل على الفائدة</p>
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <> {events?.length ? events?.map((event, index) => {
                            const imageUrl = process.env.REACT_APP_FILE_URL + event?.image;
                            const divStyle = {
                                backgroundImage: `url(${imageUrl})`,
                                backgroundRepeat: 'no-repeat'
                            };
                            const eventDate = new Date(event?.date);
                            const dayOfMonth =  moment(`${eventDate}`).utc().format('DD')
                            const eventYear = eventDate?.getFullYear();
                            const eventMonthIndex = eventDate?.getMonth();
                            const eventMonth = months[eventMonthIndex]?.ar;
                            const customDate = moment(`${eventDate}`).utc().format('YYYY-MM-DD')
                            const timeArr = moment(`${customDate} ${event?.time}`).format('hh:mm A').split(' ')
                            const time = timeArr[0]
                            const timeFormat = format[timeArr[1]]
                            const shortDescription = event?.shortDescription;
                            const description = event?.description;
                            const totelInvites = event.invitees?.length;
                            const discountPercentage = event?.discount?.value; // Discount percentage
                            // Calculate the discount amount
                            const discount = (event?.price * discountPercentage) / 100;
    
                            return (
                                <div key={index} className='event-dates-content  w-full flex 2xl:pt-16 md:pt-8 pt-4 '>
                                    <div className='d-content w-full'>
                                        <div className='event__date ml-8'>
                                            <h2 className='events_number 2xl:text-4xl  md:text-2xl pb-2'>{dayOfMonth}</h2>
                                            <p className='2xl:text-4xl  md:text-2xl font-light yellow-clr'>{eventMonth}</p>
                                        </div>
                                        <div className='event-pleasent event-third ml-8' style={divStyle}>
                                            <p className='text-white f-size-17 font-medium dark_shadow  '>{event?.name}</p>
                                        </div>
                                    </div>
                                    <div className='event-detail relative'>
                                        <div className='2xl:text-2xl lg:text-xl md:text-xl  font-bold dark_shadow 2xl:pb-6 lg:pb-4 md:pb-4 pb-2'>
                                            {!showShortDescription && shortDescription?.length > 40 ? <div>
                                                <p>{`${shortDescription?.substring(0, 40)} ....`}</p>
                                                <button className='f-size-17 font-light yellow-clr' onClick={toggleShortDescription}>اقرأ أكثر</button>
                                            </div> : shortDescription}
                                            {showShortDescription && (
                                                <><br /><button className='f-size-17 font-light yellow-clr' onClick={toggleShortDescription}>أقرأ أقل</button></>
                                            )}
                                        </div>
                                        <div className='discounted_ribbon flex items-center'>
                                            <div className="ribbon 2xl:my-2 xl:my-5 my-1 ribbon-grey ">
                                                <h3 className="dark_color 2xl:text-lg xl:text-base lg:text-sm text-xs font-bold"><span>{dayOfMonth} {eventMonth} {eventYear}</span> <span>{`${time} ${timeFormat}`}</span> {days[eventDate?.getDay() - 1]}</h3>
                                            </div>
                                            {event?.type === "Paid" ? (
                                                <div className='discount_price mr-4'>
                                                    <h6 className='bold text-lg yellow-clr bold'>${event?.discount && (new Date().toISOString().split("T")[0] >= event?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= event?.discount?.endDate.split("T")[0]) ? event?.price - discount : event?.price}</h6>
                                                    {event?.discountId && new Date().toISOString().split("T")[0] >= event?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= event?.discount?.endDate.split("T")[0] && (
                                                        <h5 className='f-size-17'>
                                                            <span className='minus-price ml-2'>${event?.price}</span> {discountPercentage}%
                                                        </h5>
                                                    )}
                                                </div>
                                            )
                                                : ""}
                                        </div>
                                        <div>
                                            <h3 className="yellow-clr 2xl:text-lg xl:text-base lg:text-sm text-xs font-bold">المدعوون {totelInvites + " / " + event?.userLimit}</h3>
                                        </div>
                                        {!showDescription && description?.length > 300 ?
                                            <div>
                                                <div className='f-size-17 font-light dark_shadow pt-4' dangerouslySetInnerHTML={{ __html: description?.substring(0, 300) + '...' }}></div>
                                                <button className='f-size-17 font-light yellow-clr' onClick={toggleDescription}>اقرأ أكثر</button>
                                            </div>
                                            :
                                            <div className='f-size-17 font-light dark_shadow pt-4' dangerouslySetInnerHTML={{ __html: description }}></div>
                                        }
                                        {showDescription && (
                                            <>
                                                <br />
                                                <button className='f-size-17 font-light yellow-clr' onClick={toggleDescription}>أقرأ أقل</button>
                                            </>
                                        )}
                                        {!isUserInvited(event?._id) ? (
                                            <div className="2xl:mt-10  md:mt-6 mt-3">
                                                {event?.type === "Free" ? (
                                                    <button className="theme-btn" onClick={() => handleJoinUs(event?._id)}>انضم إلينا</button>
                                                ) : (
                                                    <button className="theme-btn flex justify-center items-center" onClick={() => handlePay(event?._id)}>يدفع</button>
                                                )}
                                            </div>
                                        )
                                            :
                                            <div>

                                                <span className="bg-green text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full  dark:text-green-300">انضم</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }) : "لا توجد لقاءات مفتوحة قادمة في الوقت الحالي، يرجى الاشتراك في منصتنا لتصلك كافة المستجدات"}</>)}
                </div>
            </section>
        </div>
    )
}

export default Events
