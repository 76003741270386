import React, { useEffect, useState } from 'react'
import cartImage from "../../../assets/images/cart-image.png"
import ratingStar from "../../../assets/images/rating-star.svg"
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { apiHelper } from '../../../redux/apiHelper'
import { Disclosure,Transition  } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'

function CourseContent({ courseContentData }) {

    const navigate = useNavigate()
    const [totalRating, setTotalRating] = useState(0)
    let token = localStorage.getItem('token')
    const userId = JSON.parse(localStorage.getItem('userId'));
    const [user, setUser] = useState(null);
    const [currentSubscription, setCurrentSubscription] = useState("");
    let rating = 0;
    useEffect(() => {
        let totalRating = 0;
        let totalReviews = courseContentData.reviews?.filter((item) => item.status === 'Accepted').length;

        if (totalReviews > 0) {
            courseContentData.reviews.forEach(element => {
                if (element.status === 'Accepted') {
                    totalRating += element.rating;
                }
            });
            const average = totalRating / totalReviews;
            setTotalRating(Math.floor(average));
        } else {
            setTotalRating(0);
        }
    }, [courseContentData?.reviews])

    var categoryArr = {
        "Awareness": "الوعي",
        "Meditation": "التأمل",
        "Recovery": "التشافي",
    }

    const addToCart = (item) => {
        const itemData = {
            _id: item?.id,
            name: item?.title,
            price: item?.price,
            discount: item?.discount ? item.discount : 0
        }
        const existingItems = JSON.parse(localStorage.getItem("items")) || [];
        const itemExists = existingItems.some((existingItem) => existingItem._id === itemData._id);
        if (itemExists) {
            navigate("/cart")
        } else {
            existingItems.push(itemData);
            localStorage.setItem("items", JSON.stringify(existingItems));
            window.dispatchEvent(new Event("storage"));
            toast.success("Item added to the cart");

        }
    }

    const userProfile = async () => {
        try {
            const res = await apiHelper("get", `/auth/profile/${userId}`, "");
            if (res?.data) {
                const { data } = res;
                setUser(data?.user);
            }
        } catch (error) {
            console.error(error);
            toast.error(error.res.message);
        }
    };

    const fetchPlans = async (userId) => {
        try {
            let res = await apiHelper(
                "get",
                `/subscriptions/current-subscription?userId=${userId}`,
                ""
            );
            if (res?.data) {
                const { data } = res;
                setCurrentSubscription(data);
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        if (userId) {
            userProfile(userId);
            fetchPlans(userId)
        }
    }, [userId]);
    const discountPercentage = courseContentData?.discount?.value; // Discount percentage
    // Calculate the discount amount
    const discount = (courseContentData?.price * discountPercentage) / 100;
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);


    return (
        <div>
            <section className='cart--items-sec 2xl:py-24 xl:py-12 lg:py-12 py-6'>
                <div className='theme__container'>
                    <section className='add-items-section'>
                        <div className='kaif-heading text-center'>
                            <h2 className='2xl:text-7xl xl:text-5xl lg:text-4xl text-3xl  dark_color font-light'>{courseContentData?.title}</h2>
                            <p className='f-size-26 yellow-clr pt-1  text-center'>{categoryArr[courseContentData?.category]}</p>
                        </div>
                        <div className="sm:grid cmd:grid-cols-2 gap-4 2xl:mt-16 sm:mt-3">
                            {/* <div className="p-4 flex justify-between"> */}
                                <div className='cart-imgwraper relative'>
                                    <div>
                                        <img src={process.env.REACT_APP_FILE_URL + courseContentData?.thumbnailPic} alt="cart Image" />
                                    </div>
                                </div>
                            {/* </div> */}
                            <div className='cart--content mt-2 lg:mr-6'>
                                <h2 className='2xl:text-4xl font-bold md:text-3xl kaiftext-sm dark_color'>{courseContentData?.title}</h2>
                                <div className='flex items-center 2xl:mt-6 xl:mt-3 mt-4'>
                                    <div className='ribbon 2xl:my-8 xl:my-2 my-1 ml-6'>
                                        {courseContentData?.type === 'Free' ?
                                            <h3 className="text-white text-lg bold">Free</h3>
                                            : courseContentData?.type === 'Paid' ? <h3 className="text-white 2xl:text-lg xl:text-base lg:text-sm text-xs bold">{courseContentData?.discountId && new Date().toISOString().split("T")[0] >= courseContentData?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= courseContentData?.discount?.endDate.split("T")[0] ? courseContentData.currency + '' + (courseContentData?.price - discount) : courseContentData.currency + '' + (courseContentData?.price)}</h3>

                                                : <h3 className="text-white text-lg bold">Subscription</h3>
                                        }

                                    </div>

                                    <ul className='rating-stars-list'>
                                        {totalRating === 0 ? (
                                            <>
                                                {[1, 2, 3, 4, 5].map((index) => (
                                                    <li key={index}>
                                                        <img src={ratingStar} alt="cart Image" style={{ opacity: 0.5 }} />
                                                    </li>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {Array.from({ length: totalRating }, (_, index) => (
                                                    <li className='flex items-center' key={index}>
                                                        <img src={ratingStar} alt="star" />
                                                    </li>
                                                ))}
                                            </>
                                        )}
                                    </ul>
                                    <div className='flex'>
                                        <p className='light-black 2xl:text-lg xl:text-base lg:text-sm text-xs mr-5'>
                                            (<span className='font-serif'>{courseContentData?.reviews?.filter((item) => item.status === 'Accepted').length}</span> آراء المشتركين)
                                        </p>

                                    </div>
                                </div>
                                {courseContentData?.discountId && new Date().toISOString().split("T")[0] >= courseContentData?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= courseContentData?.discount?.endDate.split("T")[0] && (
                                    <h5 className='f-size-17'>
                                        <span className='minus-price ml-2'>${courseContentData?.price}</span> {discountPercentage}%
                                    </h5>
                                )}
                                <div className='product-qaulity-list'>
                                    <p className='mola font-light 2xl:text-lg xl:text-base lg:text-sm text-xs 2xl:pt-6 pt-2  max-w-2xl 2xl:pb-12 xl:pb-6 lg:pb-5 pb-4'>{courseContentData?.shortDescription}</p>
                                </div>
                                {token ?
                                    <></>
                                    : <Link to="/login" className="theme-btn my-4">سجل الان</Link>
                                }
                                {!token && courseContentData.type === 'Free' ? <></> : courseContentData.type === 'Paid' &&
                                    !user?.courses.some(course => course?.id === courseContentData?.id && course?.expiryTime >= currentTimestamp) && token ?
                                    <div className='sm:flex flex-column items-center xl:mt-7 lg:mt-5 mt-3'>
                                        <div className='flex cart--btn md:mt-0 mt-5 md:mr-3 w-full'>
                                            <div className="theme-btn add-to-cart-btn  relative">
                                                <button className="flex justify-center items-center" onClick={() => addToCart(courseContentData)}>أضف إلى السلة</button>
                                                <div className='addcart-lottie absolute'>
                                                    <lottie-player src="https://lottie.host/f8a199f5-a7aa-48ab-8fe6-fef4ccd3d569/yn6SSoAjYI.json" background="transparent" speed="1" style={{ width: '50px', height: '50px' }} loop autoplay></lottie-player>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : courseContentData.type === 'Subscriber' && (!currentSubscription?.subscription || currentSubscription?.isExpired === true) && token ?
                                        <div className='sm:flex flex-column items-center xl:mt-7 lg:mt-5 mt-3'>
                                            <div className='flex cart--btn md:mt-0 mt-5 md:mr-3 w-full'>
                                                <div className="relative">
                                                    <button className="theme-btn " onClick={() => { navigate('/plans') }}>اشترك</button>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    </section>
                    <section className='product-description  2xl:mt-20 md:mt-8 mt-6'>
                        <div className='cart--content mt-2 lg:mr-6'>
                            <h2 className='bold md:text-4xl kaiftext-sm dark_color'>وصف الدورة التدريبية</h2>
                            <div className='product-detail 2xl:my-8 xl:my-6 lg:my-5 my-4'>
                                <p className='mola 2xl:text-lg xl:text-base lg:text-sm text-xs 2xl:pb-12 lg:pb-8 sm:pb-5 pb-4 font-medium' dangerouslySetInnerHTML={{ __html: courseContentData.description }}></p>
                            </div>
                        </div>
                    </section>
                    <section className='product-description course__descrition 2xl:mt-24 md:mt-12 mt-6'>
                        <h2 className='bold md:text-4xl kaiftext-sm dark_color'> فيديو تمهيدي </h2>
                        <div className='courses_detail_video product-detail 2xl:my-8 xl:my-6 lg:my-5 my-4'>
                            <video width="700" height="500" src={process.env.REACT_APP_FILE_URL + courseContentData?.introductoryVideo} controls >
                            </video>
                        </div>
                        {token && courseContentData?.type === 'Free' || (courseContentData?.type === 'Paid' && user?.courses?.length > 0 && user?.courses?.some(course => course?.id === courseContentData?.id && course?.expiryTime >= currentTimestamp)) || (courseContentData.type === 'Subscriber' && currentSubscription?.subscription && currentSubscription?.isExpired === false) ? (
                            <>
                                <h2 className='bold md:text-4xl kaiftext-sm dark_color mb-6'>({courseContentData?.files?.length})المرفقات</h2>
                                <div className='file_format_header_wrapper'>
                                    <div className='main_file_format'>
                                        <div className='video_orginal_name'>
                                            Title
                                        </div>
                                        <div className='video_file_format'>
                                            File Format
                                        </div>
                                        <div className=' video_format_audio '>
                                            Action
                                        </div>
                                    </div>
                                    <div className='main_file_format_scroll scroll_bar'>
                                        {courseContentData?.files?.map((item, index) => {
                                            return (
                                                <div className='left-reviews-section_course main_left_part' key={index}>
                                                    <div className='self-center relative h-full'>
                                                        {
                                                            item?.mimetype === "audio/mpeg" || item?.mimetype === "audio/wav" || item?.mimetype === "audio/ogg" || item?.mimetype === "audio/aac" || item?.mimetype === "audio/flac" || item?.mimetype === "audio/mp3" ?
                                                            <Disclosure>
                                                            {({ open }) => (
                                                                <div className='mb-2'>
                                                                    <Disclosure.Button className="course_file_accordin w-full justify-between collapse_round px-4 py-4 text-right text-sm font-medium text-white hover:bg-dark-yellow  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                        <span>
                                                                        <div className='audio_corse custom_audio_course'>
                                                                            <div className='video_orginal_name'>{item?.title}</div>
                                                                            <div className='video_file_format min-width_86'>{item?.mimetype?.split('/')[1]}</div>
                                                                            <div className='video_format_audio'><audio src={process.env.REACT_APP_FILE_URL + item?.name} controls>
                                                                            </audio>
                                                                            </div>
                                                                        </div>
                                                                        </span>
                                                                        <ChevronUpIcon
                                                                            className={`${open ? 'rotate-180 transform' : ''
                                                                                } h-5 w-5 text-white`}
                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Transition
                                                                        enter="transition duration-100 ease-out"
                                                                        enterFrom="transform scale-95 opacity-0"
                                                                        enterTo="transform scale-100 opacity-100"
                                                                        leave="transition duration-75 ease-out"
                                                                        leaveFrom="transform scale-100 opacity-100"
                                                                        leaveTo="transform scale-95 opacity-0"
                                                                    >
                                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border_colapse">
                                                                         {item?.description ? item?.description : "No Description Found"}
                                                                    </Disclosure.Panel>
                                                                    </Transition>
                                                                </div>
                                                            )}
                                                        </Disclosure>
                                                                
                                                                :
                                                                item?.mimetype === "video/mp4" || item?.mimetype === "video/avi" || item?.mimetype === "video/mov" || item?.mimetype === "video/mkv" || item?.mimetype === "video/wmv" ?
                                                        <Disclosure>
                                                            {({ open }) => (
                                                                <div>
                                                                    <Disclosure.Button className="course_file_accordin w-full justify-between collapse_round px-4 py-4 text-right text-sm font-medium text-white hover:bg-dark-yellow  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                        <span>
                                                                        <div key={index} className="">
                                                                                                        <div className="artical-info corses_videos custom_artical_courses">
                                                                                                            <div className='video_orginal_name'>{item?.title}</div>
                                                                                                            <div className='video_file_format'>{item?.mimetype?.split('/')[1]}</div>
                                                                                                            <div className='video_format_audio'> <button className="theme-btn  flex justify-center items-center mt-3 mb-5 play_cus_btn" onClick={() => navigate(`/watch?file=${item?.name}&originalName=${item?.title}&coursetitle=${courseContentData?.title}&id=${courseContentData?.id}`)}>Play</button></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                        </span>
                                                                        <ChevronUpIcon
                                                                            className={`${open ? 'rotate-180 transform' : ''
                                                                                } h-5 w-5 text-white`}
                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Transition
                                                                        enter="transition duration-100 ease-out"
                                                                        enterFrom="transform scale-95 opacity-0"
                                                                        enterTo="transform scale-100 opacity-100"
                                                                        leave="transition duration-75 ease-out"
                                                                        leaveFrom="transform scale-100 opacity-100"
                                                                        leaveTo="transform scale-95 opacity-0"
                                                                    >
                                                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border_colapse">
                                                                        {item?.description  ? item?.description : "No Description Found"}
                                                                    </Disclosure.Panel>
                                                                    </Transition>
                                                                </div>
                                                            )}
                                                        </Disclosure>
                                                                  
                                                                    :
                                                                    <Disclosure>
                                                                        {({ open }) => (
                                                                            <div>
                                                                                <Disclosure.Button className="course_file_accordin w-full justify-between collapse_round px-4 py-4 text-right text-sm font-medium text-white hover:bg-dark-yellow  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                                    <span>
                                                                                    <div key={index} >
                                                                                            <div className='audio_corse custom_audio_course'>
                                                                                                <div className='video_orginal_name'>{item?.title}</div>
                                                                                                <div className='video_file_format'>{item?.mimetype?.split('/')[1]}</div>
                                                                                                <div className='video_format_audio'><a href={process.env.REACT_APP_FILE_URL + item?.name} className="theme-btn  flex justify-center items-center mt-3 mb-5 cus_download_btn" download target="_blank">Download</a></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                    <ChevronUpIcon
                                                                                        className={`${open ? 'rotate-180 transform' : ''
                                                                                            } h-5 w-5 text-white`}
                                                                                    />
                                                                                </Disclosure.Button>
                                                                                <Transition
                                                                                    enter="transition duration-100 ease-out"
                                                                                    enterFrom="transform scale-95 opacity-0"
                                                                                    enterTo="transform scale-100 opacity-100"
                                                                                    leave="transition duration-75 ease-out"
                                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                                    leaveTo="transform scale-95 opacity-0"
                                                                                >
                                                                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border_colapse">
                                                                                    {item?.description  ? item?.description : "No Description Found"}
                                                                                </Disclosure.Panel>
                                                                                </Transition>
                                                                            </div>
                                                                        )}
                                                                    </Disclosure>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        ) : <></>}
                    </section>
                </div>
            </section>
        </div>
    )
}

export default CourseContent
