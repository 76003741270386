import React, { useState } from 'react'
import UserIcon from "../../../../assets/images/UserIcon.svg"
import { Link, useNavigate } from 'react-router-dom'
import VerticalSlide from "../../../../assets/images/VerticalSlide.png"
import site_logo from "../../../../assets/images/site_logo.png";
import "../Auth.scss"
import { useDispatch } from 'react-redux'
import { VerifyPhoneCode } from '../../../../redux/auth/authActions'
import { useSelector } from 'react-redux'
import { apiHelper } from '../../../../redux/apiHelper'
import { toast } from 'react-toastify'

function VerifyCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector(state => state.auth?.user?.user?.email);
  const [phoneCode, setPhoneCode] = useState('');
  const [phoneCodeError, setPhoneCodeError] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const isValidPhoneCode = /^\d{6}$/.test(phoneCode);
    if (!isValidPhoneCode) {
      setPhoneCodeError('الرجاء إدخال رمز صحيح مكون من 6 أرقام');
      return;
    }
    const data = {
      email: email,
      phoneCode: phoneCode,
    }
    dispatch(VerifyPhoneCode(data, navigate));
  };

  const handleCodeResend = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    try {
      let res = await apiHelper('post', `/auth/resend-code`, data);
      if (res?.data) {
        const { data } = res;
        toast.success(data.message)
      }
    } catch (error) {
      toast.error(error.res.message)
      console.error(error);
    }
  };

  return (
    <div className='sign_up_wraper'>
      <div className='login_In relative'>
        <div className='site-logo desktop-block mobile-hide'>
          <Link to="/" className="site__logo pl-14">
            <img src={site_logo} alt="site_logo" className='m-auto z-10 relative pt-6' />
          </Link>
        </div>
        <div className='login-in_wraper py-20 z-10 relative'>
          <div className='theme__container flex'>
            <div className='heading-content bg-white'>
              <div className='auth-heading flex justify-between items-center'>
                <h1 className='text-4xl font-bold'>تحقق من</h1>
                <div className='user__icon'>
                  <img src={UserIcon} alt="user" />
                </div>
              </div>
              <form>
                <div className='form-box flex flex-col'>
                  <label className='dark-color f-size-17 font-bold pb-4'>بريد إلكتروني<sup className='staric'>*</sup></label>
                  <input type="text" placeholder='أكتب هنا' name="email" value={email ? email : ''} />
                </div>
                <div className='form-box flex flex-col'>
                  <label className='dark-color f-size-17 font-bold pb-4'>رمز التحقق<sup className='staric'>*</sup></label>
                  <input type="text" placeholder='أكتب هنا' onChange={(e) => setPhoneCode(e.target.value)} name="phoneCode" value={phoneCode} />
                  <span className='color-red'>{phoneCodeError ? phoneCodeError : ''}</span>
                </div>
                <div className='flex items-center mt-12 justify-between'>
                  <div className="theme-btn flex justify-center items-center ">
                    <button className="flex justify-center items-center" onClick={handleFormSubmit}>تحقق من</button>
                  </div>
                  <div className="theme-btn flex justify-center items-center ">
                    <button className="flex justify-center items-center" onClick={handleCodeResend}>أعد إرسال الرمز</button>
                  </div>
                </div>
              </form>
            </div>
            <div className='auth-left_banner verfify_left'>
              {/* <div className='login-text-left'>
                <h2 className=''>
                  لم يتأخر أبدًا ، انضم إلينا
                </h2>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyCode