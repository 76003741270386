import React from "react";

const Banner = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <section className="site__banner " id="home">
      <div className="theme__container w-full">
        <div className="relative z-10">
          <div className="shifa-content text-white leading-none">
            <h6>"إن الحياة هدية الله لك فاستقبل هديته بكل حب"</h6>
          </div>
          <div className="2xl:mt-16 md:mt-8">
            <button className="theme-btn" onClick={scrollToBottom}>
              انضم إلى مجتمعنا
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
