import React, { useEffect, useMemo, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import DataTable from 'react-data-table-component';
import FilterComponent from '../../../config/FilterComponent';
import { apiHelper } from '../../../redux/apiHelper';
import Columns from './Columns';
import Loader from '../../../config/Loader';

const UserCourses = () => {

   const [coursesData, setCourseData] = useState([]);
   const [filterText, setFilterText] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   const userId = JSON.parse(localStorage.getItem('userId'));

   const filteredItems = coursesData?.filter(
      (item) => item?.id?.title && item?.id?.title.toLowerCase().includes(filterText.toLowerCase()) ||
         item?.id?.type && item?.id?.type.toLowerCase().includes(filterText.toLowerCase()) ||
         item?.id?.category && item?.id?.category.toLowerCase().includes(filterText.toLowerCase())
   );
    
   const fetchCourseListing = async () => {
      try {
         const res = await apiHelper("get", `/courses?userId=${userId}`, "");
         if (res?.data) {
            const { data } = res;
            setCourseData(data?.courses)
         }
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      setIsLoading(true);
      fetchCourseListing().finally(() => setIsLoading(false))
   }, [userId]);

   const columns = useMemo(() => Columns(), []);

   const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {
         if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
         }
      };
      return (
         <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
   }, [filterText, resetPaginationToggle]);

   return (
      <>
         <Sidebar />
         <div className='dashboard-wraper'>
            {isLoading ? (
               <Loader />
            ) : (
               <div className='states-days common-listing'>
                  <div className='dashboard-states'>
                     <div className='states-header '>
                        <h1 className='text-white pr-6 pb-2 text-3xl sm:text-xl text-xl'>قائمة الدورات</h1>
                     </div>
                     <div className='dashboard-states-content'>
                        <DataTable
                           columns={columns}
                           data={filteredItems}
                           pagination
                           paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                           subHeader
                           subHeaderComponent={subHeaderComponentMemo}
                           persistTableHead
                        />
                     </div>
                  </div>
               </div>
            )}
         </div>
      </>
   )
}

export default UserCourses
