import React from 'react'
import LowerBanner from "../../../assets/images/lower-banner.png"
import UserProfile from "../../../assets/images/profileUse.svg"
import videocall from "../../../assets/images/videocall.svg"
import subscribed from "../../../assets/images/subscribed.svg"
import Desk from "../../../assets/images/Desk.svg"
import Tree from "../../../assets/images/Tree.svg"
import Secure from "../../../assets/images/Secure.svg"
import Chats from "../../../assets/images/Chats.svg"




import { Link } from 'react-router-dom'

function KaifTamil() {

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    };

    return (
        <div>
            <div className='KaifContent'>
                <div className='theme__container'>
                    <div className='kaif_wraper'>
                        <div className='kaif-heading text-center 2xl:pt-20 lg:pt-10 md:pt-6 pt-6 '>
                            <p className='2xl:text-3xl lg:text-2xl text-xl yellow-clr text-center'>كيف تحدث التغيير؟</p>
                            <p className='f-size-17 text-center dark_color max-w-896 m-auto pt-4 pb-4'>فيها وإبدأ بالإتساع اتخذ قرار التغيير اليوم، انطلق وإسأل وإسعى وتحرك في رحلتك للتغيير عانق التغيير ورحّب به واخلق مساحة خاصة له، كن مرناً مع التغيير الذي يحدث لك وكن واعياً لكل مرة تنكمش </p>
                            {/* <h2 className='f-size-26 font-bold  kaiftext-sm dark_color'>فيها وإبدأ بالإتساع اتخذ قرار التغيير اليوم، انطلق وإسأل وإسعى وتحرك</h2> */}
                            <p className='f-size-17 text-center dark_color max-w-3xl m-auto pt-3'>
                            انضم معنا لعالم مليء بالنور والتشافي والتغيير إذا كنت مستعداً لذلك!
                            </p>
                        </div>
                        <div className='tasjeel-content 2xl:mt-16 md:mt-10 relative'>
                            <div className="sm:grid cmd:grid-cols-2 gap-4">
                                <div className='mobile-text mt-2 mr-6'>
                                    <div className='listing_alim'>
                                        <ul>
                                            <li className='flex items-center mb-4 light_purple'><span className='circle-block f-size-26 yellow-clr ml-4'>1</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>إنشاء الملف الشخصي</p>
                                                    <p className='f-size-17 text-start dark_color pt-3 font-light'>
                                                    يمكنك التسجيل في الموقع وإنشاء ملفك الشخصي بكل سهولة ويسر لتتمكن من الحصول على الخدمات المتاحة لك
                                                    </p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={UserProfile} alt="socal-icon"/>
                                                </div>
                                            </li>
                                            <li className='flex items-center mb-4 dark-purple'><span className='circle-block f-size-26 yellow-clr ml-4'>2</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>إقتناء الدورات أو الحصول على الإستشارات</p>
                                                    <p className='f-size-17 text-start dark_color  font-light'>تعرف على الدورات وأنواع الاستشارات المقدمة من قبل م. نبيلة واقتنيها باتباع خطوات بسيطة</p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={videocall} alt="socal-icon"/>
                                                </div>
                                            </li>
                                            <li className='flex items-center mb-4 light_blue'><span className='circle-block f-size-26 yellow-clr ml-4'>3</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>الاشتراك في الموقع</p>
                                                    <p className='f-size-17 text-start dark_color  font-light'>اشترك معنا واحصل على مزايا حصرية فقط للمشتركين</p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={subscribed} alt="socal-icon"/>
                                                </div>
                                            </li>
                                            <li className='flex items-center mb-4 light_purple'><span className='circle-block f-size-26 yellow-clr ml-4'>4</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>اللقاءات المفتوحة</p>
                                                    <p className='f-size-17 text-start dark_color  font-light'>
                                                    انضم معنا في اللقاءات المفتوحة والتي تطرح العديد من المواضيع المهمة والمتعلقة بالوعي والتشافي   
                                                    </p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={Desk} alt="socal-icon"/>
                                                </div>
                                            </li>
                                            <li className='flex items-center mb-4 dark-purple'><span className='circle-block f-size-26 yellow-clr ml-4'>5</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>الخصوصية التامة</p>
                                                    <p className='f-size-17 text-start dark_color  font-light'>كن مطمئناً سيتعامل الفريق مع معلوماتك بخصوصية عالية </p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={Tree} alt="socal-icon"/>
                                                </div>
                                            </li>
                                            <li className='flex items-center mb-4 light_blue'><span className='circle-block f-size-26 yellow-clr ml-4'>6</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>طرق دفع آمنة ومتعددة</p>
                                                    <p className='f-size-17 text-start dark_color  font-light'>يضمن لكم موقعنا تقديم طرق دفع متعددة وآمنة فلا داعي للقلق</p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={Secure} alt="socal-icon"/>
                                                </div>
                                            </li>
                                            <li className='flex items-center mb-4 light_purple'><span className='circle-block f-size-26 yellow-clr ml-4'>7</span>
                                                <div>
                                                    <p className='dark_color f-size-22 text-lg listing_f-sze'>آرائكم وتعليقاتكم</p>
                                                    <p className='f-size-17 text-start dark_color  font-light'>
                                                    رأيك محل اهتمامنا، شاركنا رأيك وتعليقاتك حول ما نقدمه من خدمات ليتسنى لنا تحسين تجربتك
                                                    </p>
                                                </div>
                                                <div className='social__icons_main'>
                                                    <img src={Chats} alt="socal-icon"/>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="p-4 self-center ">
                                    <div className='mobile_wraper'>
                                        <img src={LowerBanner} alt="mbl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KaifTamil
