import React, { useEffect, useState } from 'react'
import "./CheckOut.scss";
import Visa from "../../../assets/images/Visa.png";
import Mada from "../../../assets/images/mada.png";
import Master from "../../../assets/images/master.png";
import Benift from "../../../assets/images/benefit.png";

import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios';
import Select from 'react-select';
import { apiHelper } from '../../../redux/apiHelper';
import { toast } from 'react-toastify';
import { adminApiHelper } from '../../../redux/adminApiHelper';
import Spinner from "../../../assets/images/Spinner.gif";
import Loader from '../../../config/Loader';

function CheckOut() {

    const { search } = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const type = params.get('type');
    const id = params.get('id');
    const [items, setItems] = useState([] || null);
    const [country, setCountry] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const userId = JSON.parse(localStorage.getItem('userId'));
    const userData = JSON.parse(localStorage.getItem('user'));

    const schema = yup.object().shape({
        firstName: yup.string().required('الإسم الأول مطلوب').min(3, 'قصير جدا').max(30, 'طويل جدا'),
        lastName: yup.string().required('إسم العائلة مطلوب').min(3, 'قصير جدا').max(30, 'طويل جدا'),   
        address: yup.string().required('العنوان مطلوب').min(5, 'قصير جدا').max(80, 'طويل جدا'),
        city: yup.string().required('المدينة مطلوبة').min(3, 'قصير جدا').max(50, 'طويل جدا'),
        email: yup.string().required('البريد الالكتروني مطلوب').matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'تنسيق بريد إلكتروني غير صالح'),
        phone: yup.string().required('رقم الهاتف مطلوب').matches(/^[0-9]+$/, 'يجب أن يحتوي رقم الهاتف على أرقام فقط')
            .min(11, 'يجب ألا يقل رقم الهاتف عن 11 رقمًا').max(16, 'يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر').nullable(true),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const fetchEvent = async () => {
        setIsLoading(true);
        try {
            let res = await adminApiHelper('get', `/events/${id}`, '');
            if (res?.data) {
                const { data } = res;
                setItems(data?.event)
            }
        } catch (error) {
            toast.error(error.res?.message)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchConsultation = async () => {
        setIsLoading(true);
        try {
            let res = await adminApiHelper('get', `/consultations/${id}`, '');
            if (res?.data) {
                const { data } = res;
                setItems(data?.consultation)
                let response = await adminApiHelper('get', `/settings`, '');
                if (response?.data) {
                    setItems(prevData => ({ ...prevData, price: response?.data?.consultationPrice }));
                }
            }
        } catch (error) {
            toast.error(error.res.message)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (type === 'course') {
            const storedItems = JSON.parse(localStorage.getItem('items')) || [];
            setItems(storedItems);
        } else if (type === 'event') {
            if (id) {
                fetchEvent()
            }
        } else if (type === 'consultation') {
            if (id) {
                fetchConsultation()
            }
        } else {
            setItems([]);
        }
    }, [type]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiHelper("get", `/countries`)
                 .then((data) => {
                     setCountry(data?.data?.countries);
                 });         
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Handle country
    const handleSelectChange = (_, selectedOption) => {
        setSelectedCountry(selectedOption ? selectedOption.value : "");
    };

    const calculatePrice = () => {
        let totalPrice = 0;
        let discountedPrice = 0;
        let totalDiscountedPrice = 0;
        let ids = [];
        if (type === "course") {
            items.forEach((item) => {
                totalPrice += item.price;
                ids.push(item.id);
                if (item.discount && new Date().toISOString().split("T")[0] >= item?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= item?.discount?.endDate.split("T")[0]) {
                    discountedPrice = (item.price * item.discount?.value) / 100;
                    totalDiscountedPrice += discountedPrice;
                }
            });
        } else if (type === "event") {
            if (items.discount?.value && new Date().toISOString().split("T")[0] >= items?.discount?.startDate.split("T")[0] && new Date().toISOString().split("T")[0] <= items?.discount?.endDate.split("T")[0]) {
                discountedPrice = (items.price * items.discount?.value) / 100;
                totalDiscountedPrice += discountedPrice;
            }
            totalPrice += items.price;
        }
        return {
            totalPrice: totalPrice.toFixed(2),
            totalDiscountedPrice: totalDiscountedPrice.toFixed(2),
            grandTotal: (totalPrice - totalDiscountedPrice).toFixed(2),
            ids,
        };
    };
    const { totalPrice, totalDiscountedPrice, grandTotal, ids } = calculatePrice();

    const handleFormSubmit = async (data) => {
        data.userId = userId
        data.type = type
        data.currency = "USD"
        data.country = selectedCountry
        if (type === 'course') {
            const newArray = items?.map(({ discount, ...obj }) => ({
                ...obj,
                discount: discount?.value
            }));
            data.items = newArray
            data.totalAmount = grandTotal
        } else if (type === 'event') {
            data.items = {
                name: items.name,
                price: items.price,
                discount: items.discount?.value ? items.discount?.value : 0,
                _id: items?._id
            }
            data.totalAmount = grandTotal
        } else {
            data.items = {
                name: items?.questions,
                price: items?.price,
                _id: items?._id
            }
            data.totalAmount = items?.price
        }
        setIsLoading(true);
        try {
            let res = await apiHelper('post', `/orders/`, data);
            if (res?.data) {
                const { data } = res;
                if (data && data?.paymentUrl) {
                    window.location.href = data?.paymentUrl;
                  }
            }
        } catch (error) {
            toast.error(error?.res.message)
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        
        <div className=' chechout_banner_wrapper'>
            <div className="cms-pages-baner  chechout_banner">
                <div className="theme__container">
                    <div className='cms-content-heading'>
                        <h2 className='2xl:text-8xl xl:text-7xl lg:text-5xl text-3xl  dark_color bold mb-5 text-center'>
                            الخروج الآمن
                        </h2>
                        <p className='2xl:text-3xl xl:text-2xl md:text-lg text-base font-light dark_color text-center '>
                            أكمل عملية الشراء في أقل من دقيقة
                        </p>
                    </div>
                </div>
            </div>
            <>
        {isLoading ? 
        <Loader />
       : <>
            {(type === 'event' || type === 'consultation') && isLoading ?
                <div className="loader"><img src={Spinner} alt="loader" /></div>
                :
                <section id="contact" className='contactus--form 2xl:py-28 md:py-14 py-8 cart--items-sec '>
                    <div className="theme__container">
                        <div className='sm:grid cmd:grid-cols-2 common-card-style form--wraper'>
                            <div className='contact-right-form'>
                                <div className='flex'>
                                    <h6 className='dark_color 2xl:text-4xl xl:text-3xl lg:text-xl md:text-lg text-base  font-bold'>
                                        تفاصيل الفاتورة
                                    </h6>
                                </div>
                                <form id="contact-form" className="form-horizontal" role="form" onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className="mb-5 sm:grid cmd:grid-cols-2 gap-5">
                                        <div className='flex flex-col'>
                                            <label className='d-block lable-style'>الاسم الأول<sup className="staric">*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="firstName"
                                                {...register("firstName")}
                                            />
                                            <span className='color-red'>{errors.firstName?.message}</span>
                                        </div>
                                        <div className='flex flex-col'>
                                            <label className='d-block lable-style'>اسم العائلة<sup className="staric">*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lastName"
                                                {...register("lastName")}
                                            />
                                            <span className='color-red'>{errors.lastName?.message}</span>
                                        </div>
                                    </div>
                                    <div className="mb-5 select_identity">
                                        <label className='d-block lable-style'>البلد / المنطقة<sup className="staric">*</sup></label>
                                        <Select
                                            onChange={(selectedOption) => handleSelectChange("selectedCountry", selectedOption)}
                                            options={country && country.map((selectedCountry) => ({ value: selectedCountry.name, label: selectedCountry.name }))}
                                            classNamePrefix='common-select-style'
                                            name="selectedCountry"
                                            value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                        />
                                        {/* <span className='color-red'>{errors.selectedCountry?.message}</span> */}
                                    </div>
                                    <div className="mb-5">
                                        <label className='d-block lable-style'>عنوان<sup className="staric">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="address"
                                            {...register("address")}
                                        />
                                        <span className='color-red'>{errors.address?.message}</span>
                                    </div>
                                    <div className="mb-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="optionalAddress"
                                            {...register("optionalAddress")}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <label className='d-block lable-style'>البلدة / المدينة<sup className="staric">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            {...register("city")}
                                        />
                                        <span className='color-red'>{errors.city?.message}</span>
                                    </div>
                                    <div className="mb-5">
                                        <label className='d-block lable-style'>هاتف<sup className="staric">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            {...register("phone")}
                                        />
                                        <span className='color-red'>{errors.phone?.message}</span>
                                    </div>
                                    <div className="mb-5">
                                        <label className='d-block lable-style'>عنوان البريد الإلكتروني<sup className="staric">*</sup></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            {...register("email")}
                                        />
                                        <span className='color-red'>{errors.email?.message}</span>
                                    </div>
                                    <div className='flex flex-col'>
                                        <label className='d-block lable-style'>ملاحظات الطلب (اختياري)</label>
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            name="message"
                                            {...register("message")}
                                        >
                                        </textarea>
                                    </div>
                                    <div className=" text-center  md:mt-8 mt-5 m-auto check_out-btn">
                                        <button className=" theme-btn">أكد الآن</button>
                                    </div>
                                </form>
                            </div>
                            <div className='contact--info'>
                                <h2 className='checkout-heading text-white 2xl:text-4xl xl:text-3xl lg:text-xl md:text-lg text-base font-bold text-center '>
                                    طلبك
                                </h2>
                                <div className='flex items-center justify-between padding-50'>
                                    <h3 className='text-3xl dark_color font-medium dark_shadow'>المنتج</h3>
                                    <h3 className='text-3xl dark_color font-medium dark_shadow'>المجموع</h3>
                                </div>
                                {type === 'consultation' ?
                                    <>
                                        <div className='price_listing flex items-center justify-between'>
                                            <span className='dark_color font-light dark_shadow f-size-17'>
                                                {items?.questions}
                                            </span>
                                            <span className='dark_color font-light dark_shadow f-size-17'>
                                                <span className='lato'>{items?.price}</span>$
                                            </span>
                                        </div>
                                        <hr />
                                        <div className='price_listing flex items-center justify-between bg-white'>
                                            <span className='dark_color font-medium dark_shadow f-size-17'>السعر القابل للدفع</span>
                                            <span className='dark_color font-light dark_shadow f-size-17'>
                                                <span className='lato'>{items?.price}</span>$
                                            </span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {type === 'course' ?
                                            items.map((item, index) => {
                                                return (
                                                    <div className='price_listing flex items-center justify-between' key={index}>
                                                        <span className='dark_color font-light dark_shadow f-size-17'>{item.name}</span>
                                                        <span className='dark_color font-light dark_shadow f-size-17'>
                                                            <span className='lato'>{item.price}</span>$
                                                        </span>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className='price_listing flex items-center justify-between'>
                                                <span className='dark_color font-light dark_shadow f-size-17'>
                                                    {items.name}
                                                </span>
                                                <span className='dark_color font-light dark_shadow f-size-17'>
                                                    {items.price}
                                                </span>
                                            </div>
                                        }
                                        <hr />
                                        <div className='price_listing flex items-center justify-between'>
                                            <span className='dark_color font-medium dark_shadow f-size-17'>إجمالي</span>
                                            <span className='dark_color font-light dark_shadow f-size-17'>
                                                <span className='lato'>{totalPrice}</span>$
                                            </span>
                                        </div>
                                        <div className='price_listing flex items-center justify-between'>
                                            <span className='dark_color font-light dark_shadow f-size-17'>تخفيض</span>
                                            <span className='dark_color font-light dark_shadow f-size-17'>
                                                <span className='lato'>{totalDiscountedPrice}</span>$
                                            </span>
                                        </div>
                                        <div className='price_listing flex items-center justify-between bg-white'>
                                            <span className='dark_color font-medium dark_shadow f-size-17'>السعر القابل للدفع</span>
                                            <span className='dark_color font-light dark_shadow f-size-17'>
                                                <span className='lato'>{grandTotal}</span>$
                                            </span>
                                        </div>
                                    </>
                                }
                                <div className='social-icons'>
                                    <h3 className='2xl:text-4xl xl:text-2xl lg:text-xl md:text-lg text-base font-bold text-center'>طريقة الدفع المعتمدة </h3>
                                </div>
                                <div className='banking-card mt-6'>
                                    <div className='bankind__cards flex items-center justify-center'>
                                        {/* <span className='visa_card  dark_shadow flex items-center justify-end' > <img src={Amazon} /></span> */}
                                        <span className='visa_card  dark_shadow flex items-center justify-end' > <img src={Master} /></span>
                                        <span className='visa_card  dark_shadow flex items-center justify-end' > <img src={Visa} /></span>
                                        {/* <span className='visa_card  dark_shadow flex items-center justify-end' > <img src={Mada} /></span> */}
                                        {/* <span className='visa_card f-size font-light dark_color dark_shadow flex items-center justify-end' > <img src={Benift} /></span> */}
                                        <span className='visa_card flex items-center justify-end dark_shadow '><img src={Benift} /></span>


                                    </div>
                                    <div className='banking_content'>
                                        <p className='dark_color f-size-17 opacity-70'>
                                            سيتم استخدام بياناتك الشخصية لمعالجة طلبك ودعم تجربتك في جميع أنحاء هذا الموقع ولأغراض أخرى موصوفة في سياسة الخصوصية الخاصة بنا.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }</>
            }
         </>
        </div>
         
    )
}

export default CheckOut
