import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import "./Profile.scss";
import Eye from "../../../assets/images/eye.svg"
// import CutEye from "../../../assets/images/cut_eye.svg"


import Sidebar from '../Sidebar/Sidebar';
import add_image from "../../../assets/images/bashbaord/add_image.png";
import DatePicker from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";
import { apiHelper } from '../../../redux/apiHelper';
import { toast } from 'react-toastify';
import userImage from "../../../assets/images/user-image.png";
import moment from 'moment';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Loader from '../../../config/Loader';
import axios from 'axios';

function Profile() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPasswordPassword, setShowNewPasswordPassword] = useState(false);
  const [showConfirmPasswordPassword, setShowConfirmPasswordPassword] = useState(false);
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem('userId'));
  const [user, setUser] = useState({ name: '', email: '', phone: '', address: '', profileImage: '', dateValue: null });

  const schema = yup.object().shape({
    name: yup.string().required('مطلوب اسم').min(3, 'قصير جدا').max(30, 'طويل جدا'),
    email: yup.string().required('البريد الالكتروني مطلوب').matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'تنسيق بريد إلكتروني غير صالح'),
    address: yup.string().required('العنوان مطلوب').min(5, 'قصير جدا').max(50, 'طويل جدا').nullable(true),

  })

  const passwordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required('كلمة المرور الحالية مطلوبة'),
    newPassword: yup
      .string()
      .required('كلمة المرور الجديدة مطلوبة')
      .min(6, 'يجب أن تحتوي كلمة المرور الجديدة على الأقل على 6 أحرف'),
    passwordConfirmation: yup
      .string()
      .required('تأكيد كلمة المرور مطلوب')
      .oneOf([yup.ref('newPassword'), null], 'كلمة المرور غير متطابقة'),
  });

  const combinedSchema = yup.object().shape({
    ...schema.fields,
    ...passwordSchema.fields,
  });

  // const { register, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(combinedSchema) });
  const {
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    formState: { errors: profileErrors },
    setValue: setProfileValue
  } = useForm({ resolver: yupResolver(schema) });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: passwordErrors },
    setValue: setPasswordValue
  } = useForm({ resolver: yupResolver(passwordSchema) });

  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleProfileFormSubmit = async (data) => {
    setIsLoading(true);
    const selectedUnixTime = user?.dateValue ? Math.floor(moment(user?.dateValue).toDate().getTime() / 1000) : null;

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', user.phone);
    formData.append('address', data.address);
    formData.append('DOB', selectedUnixTime);

    if (image) {
      formData.append("image", image)
    }
    try {
      const res = await axios.put(`${process.env.REACT_APP_BASE_API}/auth/profile`, formData);
      if (res?.data) {
        const { data } = res;
        toast.success(data.message)
      }
    } catch (error) {
       console.error(error)
    } finally {
      setIsLoading(false);
    }
  }

  const userProfile = async () => {
    setIsLoading(true);
    try {
      const res = await apiHelper("get", `/auth/profile/${userId}`, "");
      if (res?.data) {
        const { data } = res;
        setUser((prevUser) => ({
          ...prevUser,
          ...data?.user,
          dateValue: data?.user?.DOB ? new Date(data?.user?.DOB * 1000) : null,
        }));
        setProfileValue('name', data.user.name);
        setProfileValue('email', data.user.email);
        setProfileValue('address', data.user.address);
        setProfileValue('phone', data.user.phone);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.res.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async (data) => {
    try {
      const { currentPassword, newPassword, passwordConfirmation } = data;
      const res = await apiHelper("patch", `/auth/change-password`, { currentPassword, newPassword, passwordConfirmation });
      if (res?.data) {
        let { data } = res
        toast.success(data?.message)
      }
    } catch (error) {
      console.error(error);
      toast.error(error.res?.data?.message);
    }
  }
  useEffect(() => {
    if (userId) {
      userProfile(userId);
    }
  }, [userId]);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear());

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleNewPasswordPassword = () => {
    setShowNewPasswordPassword(!showNewPasswordPassword);
  };

  const handleConfirmPasswordPassword = () => {
    setShowConfirmPasswordPassword(!showConfirmPasswordPassword);
  };

  return (
    <div>
      <Sidebar />
      <div className='dashboard-wraper '>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className='flex sm:flex-row flex-col '>
              <div className='profile_user'>
                <div className="file-input">
                  <input type="file" id="myFileInput" name="image" accept="image/*" onChange={handleFileChange} />
                  <div className='image-size_wrapper'>
                    <img src={image ? URL.createObjectURL(image) : (user?.profileImage ? `${process.env.REACT_APP_FILE_URL}` + user.profileImage : userImage)} alt="Selected Image" className='image-size' />
                  </div>
                  <label htmlFor="myFileInput">
                    <img src={add_image} alt="Add Image" />
                  </label>
                </div>
                <div className='person_name'>اختر صورة</div>
              </div>
              <div className='personal__informa w-full'>
                <h3 className='text-2xl yellow-clr font-bold mb-8'>معلوماتي الشخصية</h3>
                <form onSubmit={handleSubmitProfile(handleProfileFormSubmit)}>
                  <div className="mb-5 sm:grid cmd:grid-cols-2 gap-5">
                    <div className="flex flex-col">
                      <label className="d-block lable-style">الاسم<sup className="staric">*</sup></label>
                      <input type="text" className="form-control" placeholder="" name="name" {...registerProfile('name')} defaultValue={user?.name} />
                      <span className='color-red'>{profileErrors.name?.message}</span>
                    </div>
                    <div className="flex flex-col">
                      <label className="d-block lable-style">البريد الإلكتروني<sup className="staric">*</sup></label>
                      <input type="text" className="form-control" placeholder="" name="email" {...registerProfile('email')} defaultValue={user?.email} />
                      <span className='color-red'>{profileErrors.email?.message}</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label className="d-block lable-style">العنوان<sup className="staric">*</sup></label>
                    <input type="text" className="form-control" placeholder="" name="address" {...registerProfile('address')} defaultValue={user.address} />
                    <span className='color-red'>{profileErrors.address?.message}</span>
                  </div>
                  <div className="mb-5 sm:grid cmd:grid-cols-2 gap-5 mt-3">
                    <div className="flex flex-col">
                      <label className="d-block lable-style">رقم الهاتف</label>
                      <input type="text" className="form-control" placeholder="" name="phone" defaultValue={user?.phone} readOnly />
                    </div>
                    <div className="mb-5">
                      <label className='d-block lable-style'>تاريخ الميلاد</label>
                      <div className='date_picker'>
                        <DatePicker maxDate={maxDate} animations={[opacity(), transition({ from: 35, duration: 800 })]} value={user.dateValue} onChange={(value) => setUser(prevUser => ({ ...prevUser, dateValue: value }))} />
                      </div>
                    </div>
                  </div>
                  <div className="xl:mt-8 mt-5 ">
                    <button className="theme-btn" type="submit">تحديث الملف</button>
                  </div>
                </form>
              </div>
            </div>
            <div className='personal__informa w-full mt-10'>
              <h3 className='text-2xl yellow-clr font-bold mb-8'>تغيير كلمة المرور</h3>
              <form onSubmit={handleSubmitPassword(handleChangePassword)}>
                <div className="mb-5 sm:grid cmd:grid-cols-3 gap-5">
                  <div className="flex flex-col "><label className="d-block lable-style">كلمة السر الحالية<sup className="staric">*</sup></label>
                    <div className='relative'>
                      <input type={showCurrentPassword ? "text" : "password"} autoComplete='off' className="form-control" id="lname" placeholder="" name="currentPassword"
                        {...registerPassword('currentPassword')} />
                      <button type="button" className='dashboard_eye-button' onClick={handleShowCurrentPassword} >
                      {showCurrentPassword ?  <div className='slash-icon' > <img src={Eye} alt="eye" /></div> :<img src={Eye} alt="eye" />}
                      </button>
                    </div>
                    <span className='color-red'>{passwordErrors.currentPassword?.message}</span>
                  </div>
                  <div className="flex flex-col "><label className="d-block lable-style">كلمة السر الجديدة<sup className="staric">*</sup></label>
                    <div className='relative'>
                      <input
                        type={showNewPasswordPassword ? "text" : "password"}
                        autoComplete='off'
                        className="form-control"
                        id="lname"
                        placeholder=""
                        name="newPassword"
                        {...registerPassword('newPassword')}
                      />
                      <button type="button" className='dashboard_eye-button' onClick={handleNewPasswordPassword}>
                        {showNewPasswordPassword ?  <div className='slash-icon' > <img src={Eye} alt="eye" /></div> :<img src={Eye} alt="eye" />}
                      </button>
                    </div>
                    <span className='color-red'>{passwordErrors.newPassword?.message}</span>
                  </div>
                  <div className="flex flex-col "><label className="d-block lable-style">إعادة كلمة السر الجديدة<sup className="staric">*</sup></label>
                    <div className='relative'>
                      <input type={showConfirmPasswordPassword ? "text" : "password"} autoComplete='off' className="form-control" id="lname" placeholder="" name="passwordConfirmation"
                        {...registerPassword('passwordConfirmation')} />
                      <button type="button" className='dashboard_eye-button' onClick={handleConfirmPasswordPassword} >
                      {showConfirmPasswordPassword ?  <div className='slash-icon' > <img src={Eye} alt="eye" /></div> :<img src={Eye} alt="eye" />}
                      </button>
                    </div>
                    <span className='color-red'>{passwordErrors.passwordConfirmation?.message}</span>
                  </div>
                </div>
                <div className="xl:mt-8 mt-5">
                  <button className="theme-btn" type='submit'>تغيير كلمة السر</button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Profile;
