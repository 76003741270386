import React, { useState } from 'react'
import Eye from "../../../../assets/images/eye.svg"
import UserIcon from "../../../../assets/images/UserIcon.svg"
import { Link, useNavigate } from 'react-router-dom'
import VerticalSlide from "../../../../assets/images/VerticalSlide.png"
import { useDispatch } from 'react-redux'
import "../Auth.scss"
import { RegisterUser } from '../../../../redux/auth/authActions'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import site_logo from "../../../../assets/images/site_logo.png";

function Signup() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const schema = yup.object().shape({
        name: yup.string().required('مطلوب اسم').min(3, 'قصير جدا').max(30, 'طويل جدا'),
        email: yup.string().required('البريد الالكتروني مطلوب').matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'تنسيق بريد إلكتروني غير صالح'),
        phone: yup.string().required('رقم الهاتف مطلوب').matches(/^[0-9]+$/, 'يجب أن يحتوي رقم الهاتف على أرقام فقط')
            .min(11, 'يجب ألا يقل رقم الهاتف عن 11 رقمًا').max(16, 'يمكن أن يتكون رقم الهاتف من 16 رقمًا على الأكثر').nullable(true),
        password: yup.string().required('كلمة المرور مطلوبة').min(5, 'قصير جدا').max(30, 'طويل جدا'),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const handleFormSubmit = (data) => {
        dispatch(RegisterUser(data, navigate));
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='sign_up_wraper'>
            <div className='login_In relative'>
                <div className='site-logo desktop-block mobile-hide'>
                    <Link to="/" className="site__logo pl-14">
                        <img src={site_logo} alt="site_logo" className='m-auto z-10 relative pt-6' />
                    </Link>
                </div>
                <div className='login-in_wraper 2xl:py-20 pb-10 pt-2 z-10 relative'>
                    <div className='theme__container flex'>
                        <div className='heading-content bg-white'>
                            <div className='auth-heading flex justify-between items-center pt-6'>
                                <h1 className='2xltext-4xl md:text-2xl text-xl font-bold'>تسجيل جديد</h1>
                                <div className='user__icon'>
                                    <img src={UserIcon} alt="user" />
                                </div>
                            </div>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <div className='form-box flex flex-col'>
                                    <label className='dark-color f-size-17 font-bold pb-4'>اسمك<sup className='staric'>*</sup></label>
                                    <input type="text" placeholder='أكتب هنا'  {...register("name")} />
                                    <span className='color-red'>{errors.name?.message}</span>
                                </div>
                                <div className='form-box flex flex-col mt-5'>
                                    <label className='dark-color f-size-17 font-bold pb-4'>بريد إلكتروني<sup className='staric'>*</sup></label>
                                    <input type="email" placeholder='أكتب هنا'  {...register("email")} />
                                    <span className='color-red'>{errors.email?.message}</span>
                                </div>
                                <div className='form-box flex flex-col mt-5'>
                                    <label className='dark-color f-size-17 font-bold pb-4'>رقم التليفون<sup className='staric'>*</sup></label>
                                    <input type="number" placeholder='أكتب هنا'  {...register("phone")} />
                                    <span className='color-red'>{errors.phone?.message}</span>
                                </div>
                                <div className='form-box flex flex-col mt-5'>
                                    <label className='dark-color f-size-17 font-bold pb-4'>كلمه السر<sup className='staric'>*</sup></label>
                                    <div className='relative'>
                                        <input type={showPassword ? "text" : "password"} placeholder="ادخل رقمك السري"  {...register("password")} />
                                        <button type="button" className='eye-button' onClick={handleTogglePassword}>
                                            {showPassword ? <div className='slash-icon' > <img src={Eye} alt="eye" /></div> : <img src={Eye} alt="eye" />}
                                        </button>
                                        <span className='color-red'>{errors.password?.message}</span>
                                    </div>
                                </div>
                                <div className='flex items-center 2xl:mt-12 mt-6 justify-between'>
                                    <div className=" w-full  ">
                                        <button className="theme-btn">تسجيل جديد</button>
                                    </div>
                                </div>
                                <div className='ribbon__wraper flex items-center 2xl:mt-12 lg:mt-8 md:mt-6 mt-6  pb-6'>
                                    <div className="ribbon 2xl:my-2 xl:my-5 my-1">
                                        <h3 className="text-white 2xl:text-lg xl:text-base lg:text-sm text-xs bold">هل لديك حساب ؟</h3>
                                    </div>
                                    <Link to="/login"><span className='color-red f-size-17 mr-3 '>العودة إلى تسجيل الدخول</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className='auth-left_banner'>
                            {/* <div className='login-text-left'>
                                <h2 className=''>
                                    لم يتأخر أبدًا ، انضم إلينا
                                </h2>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup
