import React, { useEffect, useMemo, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import DataTable from 'react-data-table-component';
import FilterComponent from '../../../config/FilterComponent';
import { apiHelper } from '../../../redux/apiHelper';
import Columns from './Columns';
import Loader from '../../../config/Loader';

const UserConsultation = () => {

   const [consultationData, setConsultationData] = useState([]);
   const [filterText, setFilterText] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
   const userId = JSON.parse(localStorage.getItem('userId'));

   const filteredItems = consultationData?.filter(
      (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) ||
         item.phone && item.phone.includes(filterText) ||
         item.email && item.email.includes(filterText) ||
         item.status && item.status.toLowerCase().includes(filterText.toLowerCase()) ||
         item.date && item.date.includes(filterText)
   );

   const fetchConsultationListing = async () => {
      try {
         const res = await apiHelper("get", `/consultations?userId=${userId}`, "");
         if (res?.data?.success) {
            const { consultations } = res?.data;
            setConsultationData(consultations)
         }
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      setIsLoading(true);
      fetchConsultationListing().finally(() => setIsLoading(false));
   }, [userId]);

   const columns = useMemo(() => Columns(), []);

   const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {
         if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
         }
      };
      return (
         <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
   }, [filterText, resetPaginationToggle]);

   return (
      <>
         <Sidebar />
         <div className='dashboard-wraper'>
            {isLoading ? (
               <Loader />
            ) : (
               <div className='states-days common-listing'>
                  <div className='dashboard-states'>
                     <div className='states-header'>
                        <h1 className='text-white pr-6 pb-2 text-3xl sm:text-xl text-xl'>قائمة الاستشارات</h1>
                     </div>
                     <div className='dashboard-states-content'>
                        <DataTable
                           columns={columns}
                           data={filteredItems}
                           pagination
                           paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                           subHeader
                           subHeaderComponent={subHeaderComponentMemo}
                           persistTableHead
                        />
                     </div>
                  </div>
               </div>
            )}
         </div>
      </>
   )
}

export default UserConsultation
