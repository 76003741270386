import React, { useEffect, useState } from 'react'
import { Disclosure,Transition  } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { adminApiHelper } from '../../../redux/adminApiHelper';
function Faq() {
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        adminApiHelper("get", `/faq/?status=${true}&pagination=${false}`)
          .then((result) => {
            setFaqs(result?.data?.faqs);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);

    return (
        <div className='faq_content'>
            <div className='theme__container'>
                <h3 class="2xl:text-4xl xl:text-3xl lg:text-2xl text-xl  dark_color bold mb-5 text-start"> الأسئلة المتكررة</h3>
                <div className="w-full ">
                    <div className="mx-auto w-full  rounded-2xl bg-white mb-6">
                    {faqs?.length ? faqs?.map((faq, idx) => {
                        return(
                        <Disclosure>
                            {({ open }) => (
                                <div className='mb-2' key={idx}>
                                    <Disclosure.Button className="flex w-full justify-between collapse_round bg-yellow px-4 py-4 text-right text-sm font-medium text-white hover:bg-dark-yellow  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                        <span>{faq?.question}</span>
                                        <ChevronUpIcon
                                            className={`${open ? 'rotate-180 transform' : ''
                                                } h-5 w-5 text-white`}
                                        />
                                    </Disclosure.Button>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 border_colapse">
                                        {faq?.answer}
                                    </Disclosure.Panel>
                                    </Transition>
                                </div>
                            )}
                        </Disclosure>
                        )
                    })
                    :
                    <h1 className='faq_no_found 2xl:text-3xl lg:2xl text-xl'>لا توجد الأسئلة الشائعة الموجودة</h1>
                    }  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
