import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { adminApiHelper } from '../../../../redux/adminApiHelper';
import { toast } from 'react-toastify';

export default function SubscribeModal() {

    let [isOpen, setIsOpen] = useState(false)
    const schema = yup.object().shape({
        firstname: yup.string().required('الإسم الأول مطلوب'),
        lastname: yup.string().required('إسم العائلة مطلوب'),
        email: yup.string().required('البريد الالكتروني مطلوب').matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'تنسيق بريد إلكتروني غير صالح'),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const handleFormSubmit = async (data) => {
        try {
            let res = await adminApiHelper('post', `/subscriber`, data);
            if (res?.data) {
                const { data } = res;
                toast.success(data.message)
                setIsOpen(false)
            }
        } catch (error) {
            toast.error(error.res.message)
        }
    };

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        reset();
        setIsOpen(true)
    }

    return (
        <>
            <div className="field-wrapper flex items-center buton-holder relative z-10">
                <label className="subscribe-lable">
                اضغط على الزر للانضمام لمجتمعنا
                </label>
                <button type="button" onClick={openModal} className="theme-btn" id="subBtn">النشرة الإخبارية</button>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed subscribe--modal inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                                    <div className='flex justify-between items-center w-100 states-header modal-header'>
                                        <Dialog.Title
                                            as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            اشترك معنا
                                        </Dialog.Title>
                                        <button
                                            type="button"
                                            className="modal-close"
                                            onClick={closeModal}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                    </div>
                                    <div className="mt-2 p-6">
                                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                                            <div className='flex flex-col mb-3'>
                                            <label className='d-block lable-style'>الاسم الأول<sup class="staric">*</sup></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                
                                                {...register("firstname")}
                                            />
                                            <span className='color-red text-right'>{errors.firstname?.message}</span>
                                            </div>
                                            <div className='flex flex-col mb-3'>
                                            <label className='d-block lable-style'>اسم العائلة<sup class="staric">*</sup></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control"
                                                
                                                {...register("lastname")}
                                            />
                                            <span className='color-red text-right'>{errors.lastname?.message}</span>
                                            </div>
                                            <div className='flex flex-col mb-3'>
                                            <label className='d-block lable-style'>البريد الإلكتروني<sup class="staric">*</sup></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="form-control" {...register("email")}
/>
                                            <span className='color-red text-right'>{errors.email?.message}</span>
                                            </div>
                                            <div className="mb-8 flex items-start justify-center">

                                                <button
                                                    type="submit"
                                                    className="theme-btn"
                                                >
                                                    اشترك
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
