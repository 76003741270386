import React from 'react';
import Eye from "../../../../assets/images/eye.svg"
import UserIcon from "../../../../assets/images/forget-icon.svg"
import VerticalSlide from "../../../../assets/images/VerticalSlide.png"
import { Link, useNavigate } from "react-router-dom"
import site_logo from "../../../../assets/images/site_logo.png";
import "../Auth.scss"
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { apiHelper } from '../../../../redux/apiHelper';
import { toast } from 'react-toastify';
import { forgetPassword } from '../../../../redux/auth/authActions';
import { useDispatch } from 'react-redux';

function Forget() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const schema = yup.object().shape({
        email: yup.string()
            .email('البريد الإلكتروني غير صالح')
            .required('البريد الإلكتروني مطلوب')
            .max(255, 'يجب ألا يتجاوز البريد الإلكتروني 255 حرفًا')
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const handleFormSubmit = async (formData) => {
        dispatch(forgetPassword(formData, navigate));
    };

    return (
        <div>
            <div className='forget_wraper'>
                <div className='login_In relative'>
                    <div className='site-logo desktop-block mobile-hide'>
                        <Link to="/" className="site__logo pl-14">
                            <img src={site_logo} alt="site_logo" className='m-auto z-10 relative pt-6' />
                        </Link>
                    </div>
                    <div className='login-in_wraper 2xl:py-10 lg:py-4 pt-2 pb-12  z-10 relative'>
                        <div className='theme__container flex'>
                            <div className='heading-content bg-white'>
                                <div className='auth-heading flex justify-between items-center 2xl:pt-16 md:pt-10 pt-2'>
                                    <h1 className='2xltext-4xl md:text-2xl text-xl font-bold'>هل نسيت كلمة السر</h1>
                                    <div className='user__icon'>
                                        <img src={UserIcon} alt="user" />
                                    </div>
                                </div>
                                <p className='f-size-17 font-light mt-2 grey-color mb-5'>نسيت كلمة السر؟ الرجاء إدخال البريد الإلكتروني المسجل. سوف تتلقى رمزًا لإنشاء كلمة مرور جديدة عبر بريدك الإلكتروني.</p>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className='form-box flex flex-col'>
                                        <label className='dark-color f-size-17 font-bold pb-4'>أدخل البريد الإلكتروني<sup className='staric'>*</sup></label>
                                        <input type="email" placeholder='أكتب هنا' {...register("email")} required />
                                        <span className='color-red'>{errors.phone?.message}</span>
                                    </div>
                                    <div className='flex items-center 2xl:mt-28 lg:mt-20 md:mt-16 mt-6 justify-between forget__btn'>
                                        <div className=" w-full  ">
                                            <button className="theme-btn">إعادة تعيين كلمة المرور</button>
                                        </div>
                                        <Link to="/login" className='forother__butn'><span className='color-red f-size-17 flex items-center'>العودة إلى تسجيل الدخول
                                            <span className='left-arow'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#B40000" height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 330 330" >
                                                    <path d="M111.213,165.004L250.607,25.607c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.858-15.355-5.858-21.213,0.001  l-150,150.004C76.58,157.211,75,161.026,75,165.004c0,3.979,1.581,7.794,4.394,10.607l150,149.996  C232.322,328.536,236.161,330,240,330s7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L111.213,165.004z" />
                                                </svg>
                                            </span></span>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                            <div className='auth-left_banner'>
                                {/* <div className='login-text-left'>
                                    <h2 className=''>
                                        لا تنس أبدًا قيمك
                                    </h2>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forget
