import React, { useEffect } from 'react'
import AlimTabs from '../AlimTabs/AlimTabs'
import { Link } from 'react-router-dom'

function AlimNabeela() {
  useEffect(()=>{
    window.scroll(0, 0)
},[])

  return (
    <div>
      <div className='alim__nabeela_content 2xl:mt-24 md:mt-16 2xl:mb-24 md:mb-16'>
        <div className=''>
          <div className='fee-alim-wrapper z-10 relative font-light text-center pt-20 tabs_pl'>
            <h2 className='text-white'>ستجد في عالم نبيلة</h2>
            <span className='yellow-clr f-size-26'>ربي يسعد هالصوت ادمنته</span>
            <div className='tabs__wraper'>
              <AlimTabs />
            </div>
          </div>
          {/* <div className="text-center">
            <Link to="/contact-us"><button className='theme-btn 2xl:mt-20 md:mt-8 mt-4 m-auto  relative courses_join_btn'>تواصل مع نبيلة</button></Link>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default AlimNabeela
