import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { adminApiHelper } from '../../../redux/adminApiHelper';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import Loader from '../../../config/Loader';

function ArticleDetail() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [detail, setDetail] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        async function FetchData() {
            await adminApiHelper("get", `/articles/${id}`).then((result) => {
                if (result?.data?.success) {
                    setDetail(result.data?.article)
                    setIsLoading(false)
                } else {
                    navigate('/articles');
                    setIsLoading(false)
                }
            }).catch((err) => {
                console.log(err, "error")
                setIsLoading(false)
            });
        }
        FetchData();
        window.scrollTo(0, 0);
    }, [])

    const imageUrl = process.env.REACT_APP_FILE_URL + detail?.image;
    const divStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat'
    };

    return (
        <>{isLoading ?
            <Loader />
            :
            <div>
                <div className='artical-detail-page'>
                    <div className="cms-pages-baner artical-detail-baner " style={divStyle}>
                        <div className="theme__container">
                            <div className='artical-detail-heading w-100 flex flex-col justify-center items-start relative z-10'>
                                <p className='2xl:text-3xl xl:text-2xl md:text-lg text-base font-light font-medium pb-3'>
                                    {detail?.shortDescription}
                                </p>
                                <h2 className='f-size-20 font-medium xl:mb-5 mb-3'>
                                    <span className='pl-2 '>{detail?.title}</span>
                                    <span className=' pr-2 border-right'>
                                        {detail?.updatedAt ? moment(detail?.updatedAt).format('DD-MM-YYYY') : 'N/A'}
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='artical-detail-content'>
                        <div className="theme__container">
                            <div className='artical-info mt-10'>
                                <p className='text-base font-light' dangerouslySetInnerHTML={{ __html: detail?.description }}>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default ArticleDetail
