import React from "react";
import { Link } from "react-router-dom";

function Faqat() {
  return (
    <div className="faqat-wraper">
      <div className="main-wraper-faqat 2xl:mt-20 lg:mt-16 md:mt-10 mt-6 relative">
        <div className="theme__container">
          <div className="faqat-text z-10 relative text-center">
            <h3 className="light-yellow font-light f-size-26">
            منصة متكاملة مليئة بالنور والتشافي والوعي والتغيير
            </h3>
            <h5 className="text-white text-4xl pt-4">
            اتخذ القرار الآن وانضم إلينا
            </h5>
            <div className="faqat-btn mt-8 w-full">
              <Link to="/signup">
                <button className=" theme-btn ">انضم إلى مجتمعنا</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqat;
