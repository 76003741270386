import React, { useState } from 'react';
import UserIcon from "../../../../assets/images/forget-icon.svg"
import { Link, useNavigate } from "react-router-dom"
import site_logo from "../../../../assets/images/site_logo.png";
import "../Auth.scss"
import { useDispatch, useSelector } from 'react-redux';
import { apiHelper } from '../../../../redux/apiHelper';
import { toast } from 'react-toastify';
import { verifyForgetCode } from '../../../../redux/auth/authActions';

function Verify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userEmail = useSelector(state => state.auth?.user?.email);
    const [phoneCode, setPhoneCode] = useState('');
    const [phoneCodeError, setPhoneCodeError] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const isValidPhoneCode = /^\d{6}$/.test(phoneCode);
        if (!isValidPhoneCode) {
            setPhoneCodeError('الرجاء إدخال رمز صحيح مكون من 6 أرقام');
            return;
        }
        const data = {
            email: userEmail,
            phoneCode: phoneCode,
        }
        dispatch(verifyForgetCode(data, navigate));
    };

    const handleCodeResend = async (e) => {
        e.preventDefault();
        const data = {
            email: userEmail,
        };
        try {
            let res = await apiHelper('post', `/auth/resend-code`, data);
            if (res?.data) {
                const { data } = res;
                toast.success(data.message)
            }
        } catch (error) {
            toast.error(error.res.message)
            console.error(error);
        }
    };

    return (
        <div>
            <div className='forget_wraper'>
                <div className='login_In relative'>
                    <div className='site-logo desktop-block mobile-hide'>
                        <Link to="/" className="site__logo pl-14">
                            <img src={site_logo} alt="site_logo" className='m-auto z-10 relative pt-6' />
                        </Link>
                    </div>
                    <div className='login-in_wraper 2xl:py-10 lg:py-4 pt-2 pb-12  z-10 relative'>
                        <div className='theme__container flex'>
                            <div className='heading-content bg-white'>
                                <div className='auth-heading flex justify-between items-center 2xl:pt-16 md:pt-10 pt-2'>
                                    <h1 className='2xltext-4xl md:text-2xl text-xl font-bold'>هل نسيت كلمة السر</h1>
                                    <div className='user__icon'>
                                        <img src={UserIcon} alt="user" />
                                    </div>
                                </div>
                                <p className='f-size-17 font-light mt-2 grey-color mb-5'>هل نسيت كلمة المرور؟ يرجى إدخال البريد الإلكتروني المسجل. ستتلقى رمزًا لإنشاء كلمة مرور جديدة عبر بريدك الإلكتروني.</p>

                                <form>
                                    <div className='form-box flex flex-col'>
                                        <label className='dark-color f-size-17 font-bold pb-4'>أدخل البريد الإلكتروني<sup className='staric'>*</sup></label>
                                        <input type="text" placeholder='أكتب هنا' name="email" value={userEmail ? userEmail : ''} disabled />
                                    </div>
                                    <div className='form-box flex flex-col'>
                                        <label className='dark-color f-size-17 font-bold pb-4'>رمز التحقق<sup className='staric'>*</sup></label>
                                        <input type="text" placeholder='أكتب هنا' onChange={(e) => setPhoneCode(e.target.value)} name="phoneCode" value={phoneCode} />
                                        <span className='color-red'>{phoneCodeError ? phoneCodeError : ''}</span>
                                    </div>
                                    <div className='flex items-center mt-12 justify-between'>
                                        <div className="theme-btn flex justify-center items-center ">
                                            <button className="flex justify-center items-center" onClick={handleFormSubmit}>تحقق من</button>
                                        </div>
                                        <div className="theme-btn flex justify-center items-center ">
                                            <button className="flex justify-center items-center" onClick={handleCodeResend}>أعد إرسال الرمز</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='auth-left_banner  '>
                                {/* <div className='login-text-left'>
                                    <h2 className=''>
                                        لا تنس أبدًا قيمك
                                    </h2>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Verify
