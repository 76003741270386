import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Top from "../../../assets/images/Top.png"
import { ReactComponent as Twiter } from "../../../assets/images/twiter.svg";
import { ReactComponent as Insta } from "../../../assets/images/insta.svg";
import { ReactComponent as Facebook } from "../../../assets/images/facebook.svg";
import { ReactComponent as Youtube } from "../../../assets/images/youtube.svg";
import SubscribeModal from "./Modals/SubscribeModal"
import { adminApiHelper } from '../../../redux/adminApiHelper'

const Footer = () => {

	const year = new Date()
	const [setting, setSetting] = useState();
	let token = localStorage.getItem('token')
	
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		async function FetchData() {
			await adminApiHelper("get", `/settings`).then((result) => {
				setSetting(result?.data);
			}).catch((err) => {
				console.log("error", err)
			});
		}
		FetchData();
	}, [])

	return (
		<div className='footer relative'>
			<div className='albi-content'>
				<div className="albi-listing pt-16">
					<ul className='flex items-center justify-center relative z-10'>
						<Link to="/"> <li className='text-white text-base pl-6'>الرئيسية</li></Link>
						<Link to="/courses"> <li className='text-white text-base pl-6'>الدورات</li></Link>
						<Link
							to="/events"
						>
							<li className='text-white text-base pl-6'>اللقاءات</li>
						</Link>
						{
						token &&
							<Link
							to="/consultation"
						>
							<li className='text-white text-base pl-6'>الاستشارات</li>
						</Link>
						}
						<Link
							to="/articles"
						>
							<li className='text-white text-base pl-6'>مقالات</li>
						</Link>
						<Link
							to="/aboutus"
						>
							<li className='text-white text-base pl-6'>عالم نبيلة</li>
						</Link>
						<Link
							to="/contact-us"
						>
							<li className='text-white text-base pl-6'>اتصل بنا</li>
						</Link>
						<Link to="/privicy-policy"> <li className='text-white text-base pl-6'>سياسة الخصوصية</li></Link>
						<Link to="/terms"> <li className='text-white text-base pl-6'>شروط الإستخدام</li></Link>
						<Link to="/faq"> <li className='text-white text-base pl-6'>التعليمات</li></Link>

					</ul>
				</div>
				<div className='flex items-center justify-center'>
					<SubscribeModal />
				</div>
				<div className="socil-listing mt-6 ">
					<ul className='flex items-center justify-center relative z-10'>
						<Link to={setting?.telegram}> <li className='text-white text-base ml-3'><Twiter /></li></Link>
						<Link to={setting?.instagram}> <li className='text-white text-base ml-3'><Insta /></li></Link>
						<Link to={setting?.facebook}> <li className='text-white text-base ml-3'><Facebook /></li></Link>
						<Link to={setting?.youtube}> <li className='text-white text-base ml-3'><Youtube /></li></Link>
					</ul>
				</div>
				<p className='text-center copy-right'>جميع الحقوق محفوظة © <span className='lato'>{year.getFullYear()}</span></p>
			</div>
			<div className="top-scroll-img">
				<button type='button' onClick={scrollToTop}>
					<img src={Top} alt="Top" />
				</button>
			</div>
		</div>
	)
}

export default Footer
