import React from 'react';
import { Route, Routes } from 'react-router-dom';

// __ __ Style Libaries/Components __ __ //
import TopBarProgress from 'react-topbar-progress-indicator';
import { ToastContainer } from "react-toastify";
import './assets/css/index.css';
import "./assets/css/theme.scss";
import "./assets/css/responsive.scss";
// __ __ Components/Helpers __ __ //
import Layout from './components/layout/layout.js';
import ProtectedRoute from "./routeProtection/ProtectedRoute";
import RedirectToDashboard from "./routeProtection/RedirectToDashboard";

import Home from "./components/main/Home/Home.jsx"
import Consultation from "./components/main/Consultation/Consultation"
import Profile from "./components/Dashboard/Profile/Profile";
import Events from "./components/main/Events/Events";
import Course from "./components/main/Course/Course";
import Articles from "./components/main/Articles/Articles";
import AboutUs from "../src/components/main/AboutUs/AboutUs";
import ContactUs from "./components/main/ContactUs/ContactUs";
import Dashboard from "./components/Dashboard/Dashboard";
import UserEvents from "./components/Dashboard/Events/UserEvents";
import Plans from "./components/Dashboard/Plans/List";
import CmsPages from "./components/common/PrivacyPolicy/PrivacyPolicy";
import Cart from "./components/main/Cart/Cart";
import Payment from "./components/Dashboard/Payment/Payment";
import UserCourses from './components/Dashboard/Course/UserCourses';
import UserConsultation from './components/Dashboard/Consultation/UserConsultation';
import PageNotFound from './components/common/PageNotFound/PageNotFound';
import FaqPage from './components/main/Faq/FaqPage';
import MySubscriptions from './components/Dashboard/MySubscriptions/Subscriptions';
import PaymentCallback from './components/common/PaymentCallback/PaymentCallback';
import CheckOut from './components/main/CheckOut/CheckOut';
import CourseDetails from './components/main/CourseDetails/CourseDetails';
import CourseVideo from './components/main/CourseDetails/CourseVideo';
import ArticleDetail from './components/main/Articles/ArticleDetail';
import Login from "./components/main/Auth/Login/Login";
import SignUp from './components/main/Auth/SignUp/SignUp';
import Forget from './components/main/Auth/Forget/Forget';
import Terms from './components/common/Terms/Terms';
import ResetPassword from './components/main/Auth/Forget/ResetPassword';
import Verify from './components/main/Auth/Forget/Verify';
import VerifyCode from './components/main/Auth/VerifyCode/VerifyCode';

const App = () => {
	return (
		<React.Suspense>
			<Routes>
				<Route path="/" element={<Layout />}>
					
					<Route index element={<Home />} />
					<Route path='/courses' element={<Course />} />
					<Route path='/articles' element={<Articles />} />
					<Route path='/articles/:id' element={<ArticleDetail />} />
					<Route path='/course/:id' element={<CourseDetails />} />
					<Route path='/aboutus' element={<AboutUs />} />
					<Route path='/contact-us' element={<ContactUs />} />
					<Route path='/events' element={<Events />} />
					<Route path='/privicy-policy' element={<CmsPages />} />
					<Route path='/cart' element={<ProtectedRoute><Cart /></ProtectedRoute>} />
					<Route path='/checkout' element={<ProtectedRoute><CheckOut /></ProtectedRoute>} />
					<Route path='/consultation' element={<Consultation />} />
					<Route path='/payment-callback' element={<ProtectedRoute><PaymentCallback /></ProtectedRoute>} />
					<Route path='/terms' element={<Terms />} />
					<Route path='/faq' element={<FaqPage />} />
					<Route path='/watch' element={<CourseVideo />} />
					<Route path='/plans' element={<Plans />} />
					<Route path='*' exact={true} element={<PageNotFound />} />
				</Route>

				<Route path='/login' element={<RedirectToDashboard><Login /></RedirectToDashboard>} />
				<Route path='/signup' element={<RedirectToDashboard><SignUp /></RedirectToDashboard>} />
				<Route path='/forget' element={<RedirectToDashboard><Forget /></RedirectToDashboard>} />
				<Route path='/reset-password' element={<RedirectToDashboard><ResetPassword /></RedirectToDashboard>} />
				<Route path='/verify-code' element={<VerifyCode />} />
				<Route path='/verify' element={<Verify />} />
				<Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
				<Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
				<Route path='/user-courses' element={<ProtectedRoute><UserCourses /></ProtectedRoute>} />
				<Route path='/user-consultations' element={<ProtectedRoute><UserConsultation /></ProtectedRoute>} />
				<Route path='/payments' element={<ProtectedRoute><Payment /></ProtectedRoute>} />
				<Route path='/user-events' element={<ProtectedRoute><UserEvents /></ProtectedRoute>} />
				<Route path='/subscriptions' element={<ProtectedRoute><MySubscriptions /></ProtectedRoute>} />

			</Routes>
		</React.Suspense>
	);
}

export default App;
